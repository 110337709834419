
<div class="confirmation-container" data-testid="confirmation-modal">
	<div mat-dialog-title class="H3Black700 text-centered">
		{{ title }}
	</div>
	<div mat-dialog-content class="H6Black500Regular text-centered">
		<p>{{ text }}</p>
	</div>
	<div mat-dialog-actions class="dialog-actions">
		<button
			*ngIf="showCancel"
			mat-button
			class="back-button"
			data-testid="confirmation-cancel"
			(click)="close()">
			{{ cancelText }}
		</button>
		<button
			mat-stroked-button color="primary"
			[ngClass]="{'only-button' : !showCancel}"
			data-testid="confirmation-confirm"
			(click)="confirmationAction()">
			{{ buttonText }}
		</button>
	</div>
	<button mat-icon-button class="floating-close-button" matTooltip="Close" (click)="close()">
		<mat-icon>close</mat-icon>
	</button>
</div>
