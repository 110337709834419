

<div class="share-link" [ngClass]="{'share-link-dialog': isDialog}">

	<ng-container *ngIf="isDialog">
		<button
				data-testid="close-share-link"
				class="floating-close-button"
				matTooltip="Close"
				mat-icon-button
				(click)="close()">
			<mat-icon>close</mat-icon>
		</button>

		<div class="share-link-title">Share {{ shareType | titlecase }}</div>

		<div *ngIf="isLoading" class="loading-container">
			<div class="dot-stretching"></div>
		</div>

		<ng-container *ngIf="!isLoading">
			<div class="share-link-subtitle shareables-selector" *ngIf="shareablesList">
				<span>Select which <b>{{ shareType }}</b> to share:</span>
				<mat-form-field subscriptSizing="dynamic" appearance="fill">
					<mat-select
								[value]="shareablesList[0]"
								(selectionChange)="updateShareable($event.value)"
								class="H7Black700Regular">
						<mat-option class="H7Black700Regular" *ngFor="let shareable of shareablesList" [value]="shareable">{{shareable.name}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="share-link-subtitle" *ngIf="shareState || canShare">
				Use the direct link to share <b>{{ shareName }}</b> with anyone.<br>
			</div>

			<div class="share-link-subtitle" *ngIf="!(shareState || canShare)">
				Unable to share <b>{{ shareName }}</b> because sharing has not been enabled!<br>
				Contact an authorized user within your organization to enable sharing.
			</div>

			<div class="share-link-toggle" *ngIf="setLinkAbilityFunc && canShare">
				<mat-slide-toggle [(ngModel)]="shareState" (change)="onToggle($event);" hideIcon>{{ shareState ? 'Disable Sharing' : "Enable Sharing" }}</mat-slide-toggle>
			</div>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="!isLoading">
		<div class="share-link-content" *ngIf="(canShare || shareState)">
			<mat-form-field subscriptSizing="dynamic" appearance="fill" *ngIf="shareLink" class="invite-link">
				<input data-testid="share-link-input" matInput [value]="shareLink" readonly [disabled]="!shareState">
			</mat-form-field>

			<span class="invite-link" *ngIf="!shareLink">
				<button mat-stroked-button color="accent" (click)="createLink()">
					Create Link
				</button>
			</span>
			<button class="copy-button" mat-stroked-button color="primary" [disabled]="!(shareLink && shareState)" (click)="copyLink(shareLink);">Copy</button>
		</div>

		<div class="alert-text">
			<div *ngIf="linkCopied && shareState" class="P1Black500">Successfully copied the link</div>
		</div>
	</ng-container>
</div>
