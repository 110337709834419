
/* Imports */
import { Injectable } from '@angular/core';
import {  Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
/* Services */
import { HttpService } from './http.service';
import { getFileExtension } from './utils.service';
import { flagLayer, availableFeatureFlags } from "@shared/featureFlags";

/* Models */
import {Model, ProcessOrder} from '../models';
import { HttpEvent } from "@angular/common/http";
import { makeURLParams } from "./utils.service";
import { string } from 'yargs';
import { isEmpty, isNil } from "lodash";

export enum MODELTYPES {
	point = "point",
	mesh = "mesh",
	ortho = "ortho"
}

export interface FileData {
	name: string;
	extension: string;
	route: string;
}

export interface ImportFile {
	file: File,
	selectedFileData: FileData,
	fileData
}

export const makePublicShareLink = (public_guid: string) =>
	window.location.origin + '/#/v?s=' + public_guid;

@Injectable()
export class ModelService {
	private processOrder: BehaviorSubject<any>;
	private processList: BehaviorSubject<any>;

	constructor(
		private httpService: HttpService
	) {
		this.processOrder = new BehaviorSubject<any>(null);
		this.processList = new BehaviorSubject<any>(null);

	}	// End-of constructor

	/**
	 * v2 Routes
	 **/

	public getOverlayV2(layerId, isViewer = true): Promise<any> {
		const url = `/v2/overlays/${layerId}?type=${isViewer ? 'viewer' : 'source'}`;
		return this.httpService.get(url);
	}

	public updateOverlayV2(layerId, name): Promise<any> {
		const url = `/v2/overlays/${layerId}`;
		return this.httpService.post(url, { name });
	}

	public getModelV2(id): Promise<any> {
		let url = `/v2/model/${id}`;
		return this.httpService.get(url);
	}	// End-of getList

	public getListV2({project_id = null, organization_id = null}): Promise<any> {
		const searchData = { project_id, organization_id };
		const searchParams = makeURLParams(searchData);
		let url = '/v2/models' + searchParams;
		return this.httpService.get(url);
	}	// End-of getList

	public createV2(model): Promise<any> {
		const url = '/v2/models';
		return this.httpService.post(url, model);
	}   // End-of postModel

	public uploadModelV2(file: File, model_id: number, route: string): Promise<any> {
		if (isEmpty(route) || (typeof route !== "string")) throw new Error("Route is of incorrect format");
		if (isNil(model_id)) throw new Error("No model ID passed");
		if (file.name) {
			const url = `/v2/models/${model_id}/${route}`;
			let formData = new FormData();
			formData.set('file', file);
			return this.httpService.post(url, formData);
		} else {
			throw new Error('File is not an accepted format');
		}
	}	// End-of uploadModel

	public uploadModelWithPercentageV2(file: File, model_id: number, route: string, altQuery?: string): Observable<HttpEvent<any>> {
		if (isEmpty(route) || (typeof route !== "string")) throw new Error("Route is of incorrect format");
		if (isNil(model_id)) throw new Error("No model ID passed");
		if (file.name) {
			let url = `/v2/models/${model_id}/${route}`;
			if (altQuery) url += altQuery;
			let formData = new FormData();
			formData.set('file', file);
			return this.httpService.postProgress(url, formData);
		} else {
			throw new Error('File is not an accepted format');
		}
	}	// End-of uploadModel

	public getLayerResource(resourceUrl: string) {
		return this.httpService.get(resourceUrl);
	}
	public createProcessOrderV2(order: ProcessOrder): Promise<any> {
		let url = "/v2/orders/process";
		return this.httpService.post(url, order);
	}	// End-of createProcessOrder

	public removeV2(model: Model) {
		const url = `/v2/model/${model.id}`;
		return this.httpService.delete(url);
	}

	/**
	 * v1 Routes
	 **/

	getProcess(): Observable<any> {
		return this.processOrder.asObservable();
	}
	setProcess(val: any): void {
		this.processOrder.next(val);
	}

	getProcessList(): Observable<any> {
		return this.processList.asObservable();
	}
	setProcessList(val: any): void {
		this.processList.next(val);
	}
	getProcessStatusById(id: number): Observable<any> {
		return this.getProcessList().pipe(
			map(list => list.find(x => x.id === id))
		)
	}

	/**
	 * [GETBYID]
	 *
	 * @param {number} modelId		ID of model
	 */
	public getById(modelId: number): Promise<any> {
		let url = '/models/' + modelId;
		return this.httpService.get(url);
	}	// End-of getById

	/**
	 * [GETLIST]
	 *
	 */
	public getList(): Promise<any> {
		let url = '/models';
		return this.httpService.get(url);
	}	// End-of getList

	/**
	 * [GETPROCESSINGLIST]
	 *
	 */
	public getProcessingList(): Promise<any> {
		let url = '/models/processing';
		return this.httpService.get(url);
	}	// End-of getProcessingList

	/**
	 * [CREATE]
	 *
	 * @param { object } model		model data, including name and project_id
	 */
	public create(model): Promise<any> {
		const url = '/models';
		return this.httpService.post(url, model);
	}	// End-of create

	public createProcess(model_id: number, order: ProcessOrder): Promise<any> {
		let url = `/models/${model_id}/process`;
		return this.httpService.post(url, order);
	}	// End-of create

	/**
	 * [CREATEPROCESSORDER]
	 *
	 * @param {ProcessOrder} order		Process order request
	 */
	public createProcessOrder(order: ProcessOrder): Promise<any> {
		let url = "/orders/process";
		return this.httpService.post(url, order);
	}	// End-of createProcessOrder

	/**
	 * [UPDATE]
	 *
	 * @param {Model} model		Model data
	 */
	public update(model: Model): Promise<any> {
		let url = '/models/' + model.id;
		return this.httpService.put(url, model);
	}	// End-of update

	/**
	 * [TRASH]
	 *
	 * @param {Model} model		Model data
	 */
	public trash(model: Model): Promise<any> {
		model.trash = 1;
		return this.update(model);
	}	// End-of trash

	/**
	 * [RECOVER]
	 *
	 * @param {Model} model		Model data
	 */
	public recover(model: Model): Promise<any> {
		model.trash = 0;
		return this.update(model);
	}	// End-of recover

	/**
	 * [REMOVE]
	 *
	 * @param {Model | number} model		Model data or id
	 */
	public remove(model: Model | number): Promise<any> {
		let url = '/models/'; + (typeof model === 'object') ? model["id"] : model;
		return this.httpService.delete(url);
	}	// End-of remove

	/**
	 * [CREATEORMODIFYSHARE]
	 *
	 * @param {number} modelId					Model id
	 * @param {boolean} linkActive				Activate/deactivate share
	 */
	public createOrModifyShare(modelId: number, linkActive: boolean = true): Promise<any> {
		let url = '/models/' + modelId + "/share";
		return this.httpService.post(url, { public_share: linkActive });
	}	// End-of createOrModifyShare

	/**
	 * [GETPUBLICSHARE]
	 *
	 * @param {string} shareGuid		GUID of the shared link
	 */
	public getPublicShare(shareGuid: string): Promise<any> {
		let url = '/models/public/' + shareGuid;
		return this.httpService.get(url);
	}	// End-of getPublicShare


	/**
	 * [UPLOADMODEL]
	 *
	 * @param {string | ArrayBuffer} data		File data
	 * @param {File} file						Uploaded file with info
	 * @param {Number} model_id					Model Id
	 * @param {string} route					Route Ending
	 */
	public uploadModel(file: File, model_id: number, route: string): Observable<HttpEvent<any>> {

		if (file.name) {
			const url = `/models/${model_id}/${route}`;
			let formData = new FormData();
			formData.set('file', file);
			return this.httpService.postProgress(url, formData);
		} else {
			throw new Error('File is not an accepted format');
		}
	}	// End-of uploadModel

	public updateLayer(layer): Promise<any> {
		let url = '';
		//Orthos
		if (layer.originTable === "geotiff") {
			url = '/geotiffs/' + layer.id;
		}
		//Point clouds
		if (layer.originTable === "potree") {
			url = '/potree/' + layer.id;
		}
		//3D Mesh
		if (layer.originTable === "cesium") {
			url = '/cesium/' + layer.id;
		}
		return this.httpService.put(url, layer);
	}

}	// End-of class ModelService
