
<div class="export-modal" *ngIf="parentProject">
	<div class="header-container">
		<button
				class="floating-close-button"
				matTooltip="Close"
				mat-icon-button
				(click)="close()">
			<mat-icon>close</mat-icon>
		</button>

		<div class="header">
			Import Custom Markers
			<br/>
			<i>{{parentProject.name}}</i>
		</div>
	</div>

	<div class="selection-container">
		<div class="title">
			Custom Marker CSV
		</div>
		<div class="layer-group-list-div">
			<textarea class=csv-textarea
				(input)="updateCSV($event)"></textarea>
		</div>

		<div class="title sub-section">
			Custom Marker Details
		</div>
		
		<h4>{{csvParseMessage}}</h4>

		<div> 
			<label>Marker Count: </label> {{markerCount}}
		</div>
		<div>
			<label>Fields: </label> {{markerFields.length}}
		</div>
		<div>
			{{markerFields.toString()}}
		</div>
	</div>

	<div class="action-container">
		<button mat-flat-button color="primary"
				class="export-button"
				[disabled]="markerCount < 1"
				(click)="importMarkers()">
			Import
		</button>
	</div>
</div>
