/* Imports */
import * as THREE from "three";
import { Measurement, MeasurementService } from "@shared/services";
import { analyticsLayer } from "@shared/analyticsLayer";
import { availableFeatureFlags, flagLayer } from "@shared/featureFlags";

/* RXJs */
import { BehaviorSubject } from "rxjs";

import L from "leaflet";
import calc from "./calc";
import { v4 as uuidv4 } from "uuid";
import * as turf from "@turf/turf";
import angle from "@turf/angle";
import { isEmpty, isEqual, isNil } from "lodash";
import { Project } from "@shared/models";
import $ from "jquery";
import { ConvexGeometry } from 'three/examples/jsm/geometries/ConvexGeometry.js'
import { CustomMark2D } from "./annotate";

/**
 * Used to track what the *next* API action should be.
 * The primary use case is to note when a measurement has been marked to be deleted
 * TODO: how much of this is necessary if we can track the current request with `pendingRequest`
 */
enum PendingActionType {
	NONE = "NONE",
	CREATE = "CREATE",
	UPDATE = "UPDATE",
	DELETE = "DELETE"
}

// Annotation Type enum needs to go here for now, due to initialization ordering
export enum AnnotationType {
	MARKER = "marker", // Marker Annotation
	POLYLINE = "polyline", // Shape Annotation
	BOX_LABEL_SET = 'labelSet', // Box Label Set (for object detection AI training)
	POLY_LABEL_SET = 'polyLabelSet', // Polygon Label Set (for segmentation AI training)
	TRAINING_REGION = 'trainingRegion', // Training Region (for denotating tiles to use in AI training)
	CUSTOM_MARKER = "customMarker", // Custom Marker Annotation
}

export enum MeasurementColor {
	DEFAULT = "#cccccc",
	SELECTED = "#005dea",
}

export class Measure {

	/* Options - Type number/boolean can be inferred from the primitive value so no type needed*/
	public showDistances = true;
	public showArea = true;
	public showAngles = true;
	public showCoordinates = true;
	public showEdges = true;
	public closed = false;
	public maxMarkers = Infinity;
	public pendingRequest: Promise<Measurement> = null;
	public mapwareViewer;
	public selected: boolean = false;
	public extraInfo = {};

	constructor(
		points?: Array<any>,
		params = {}
	) {
		Object.assign( this, params ); // will only overwrite the default with values that are in `params`, everything stays
	}	// End-of constructor

	private _id: number;

	public get id(): number {
		return this._id;
	}

	public set id( id ) {
		this._id = id;
	}

	private _project: Project;

	public get project(): Project {
		return this._project;
	}

	public set project( project ) {
		this._project = project;
	}

	private _model: number;

	public get model(): number {
		return this._model;
	}

	public set model( model ) {
		this._model = model;
	}

	private _model_id: number;

	public get model_id(): number {
		return this._model_id;
	}

	public set model_id( model_id ) {
		this._model_id = model_id;
	}

	private _name: string = uuidv4(); // the API requires a name, even if the user hasn't set one. This will often be overwritten with a generic name

	public get name(): string {
		return this._name;
	}

	public set name( n: string ) {
		this._name = n;
	}

	private _type: string = uuidv4();

	public get type(): string {
		return this._type;
	}

	public set type( n: string ) {
		this._type = n;
	}

	private _details: string = "";

	public get details(): string {
		return this._details;
	}

	public set details( d: string ) {
		this._details = d;
	}

	private _created_by_id: string;

	public get created_by_id(): string {
		return this._created_by_id;
	}

	public set created_by_id( userId: string ) {
		this._created_by_id = userId;
	}

	private _points: Array<any> = [];

	public get points(): Array<any> {
		return this._points;
	}

	public set points( points ) {
		this._points = points;
	}

	private _measurementService: MeasurementService;

	public get measurementService(): MeasurementService {
		return this._measurementService;
	}

	public set measurementService( measurementService: MeasurementService ) {
		this._measurementService = measurementService;
	}

	private _pendingAction: PendingActionType = PendingActionType.NONE;

	public get pendingAction(): PendingActionType {
		return this._pendingAction;
	}

	public set pendingAction( pendingAction ) {
		// avoid sending updates and deletes for a measurement that isn't there
		const hasBeenCreated = this.id != undefined || this.pendingRequest;

		if ( hasBeenCreated ) {
			if ( pendingAction === PendingActionType.CREATE ) {
				return;
			}

			if ( this._pendingAction === PendingActionType.NONE && !this.pendingRequest ) {
				this.sendRequest( pendingAction );
			} else if ( this._pendingAction !== PendingActionType.DELETE ) {
				this._pendingAction = pendingAction;
			}
		} else {
			if ( pendingAction === PendingActionType.CREATE ) {
				this.sendRequest( pendingAction );
			}
		}
	}

	static isPoint( m ) { // for use by the app before we know what type of measurement a point belongs to
		const point = m?.data?.point ?? {};
		return !isEmpty( point ) && "lat" in point && "lng" in point; // ortho view uses latitude and longitude, where as the point(3d) view uses x,y,z
	}

	static pointsXYZtoLatLngAlt( points ) {
		return points.map( point => {
			if ( "lat" in point && "lng" in point ) return point;
			const { x, y, z } = point;
			return { lat: x, lng: y, alt: z };
		} );
	}

	static pointsLatLngAlttoXYZ( points ) {
		return points.map( point => {
			const { lat, lng, alt } = point;
			return { x: lat, y: lng, z: alt };
		} );
	}

	createMeasurement(): Promise<any> {
		let data: any = {
			name: this.name,
			type: this.type,
			details: this.details,
			points: this.points
		}
		if(this.type == AnnotationType.CUSTOM_MARKER) {
			//@ts-ignore
			data.fields = this.fields;
		}
		return this._measurementService
			.create( data )
			.then( ( res: any ) => {
				this.id = Number( res.id );
				analyticsLayer.trackMeasure( "add", this );
			} );
	}

	updateMeasurement() {
		analyticsLayer.trackMeasure( "update", this );
		let data = {
			measurementId: this.id,
			name: this.name,
			details: this.details,
			points: this.points
		};

		if(this.type === AnnotationType.CUSTOM_MARKER) {
			//@ts-ignore
			data['fields'] = this.fields;
		}
		return this._measurementService.update( data );
	}

	deleteMeasurement() {
		analyticsLayer.trackMeasure( "remove", this );
		return this._measurementService.delete( { measurementId: this.id } );
	}

	sendRequest( reqType ): void {

		const requestMapper = { // this is cleaner than using an if/else block or nested ternaries
			default: () => Promise.reject( { measurementRequestType: reqType } ), // If we've hit the default then we are in trouble
			[ PendingActionType.CREATE ]: () => // use a function to ensure the promise only gets fired if/when we want to
				this.createMeasurement(),
			[ PendingActionType.UPDATE ]: () =>
				this.updateMeasurement(),
			[ PendingActionType.DELETE ]: () =>
				this.deleteMeasurement()
		};

		if (reqType !== PendingActionType.NONE ) {
			this.pendingRequest = ( requestMapper[ reqType ] || requestMapper.default )()
				.catch( ( e ) => console.error( e ) )
				.then( () => {
					this.pendingRequest = null;
					if ( this._pendingAction !== PendingActionType.NONE ) { // If the next action is already queued up, call it
						this.sendRequest( this._pendingAction );
					}
				} );
		}

		this._pendingAction = PendingActionType.NONE;
	}

	setName( name: string ): void {
		this.name = name;
	}

	setDetails( d: string ): void {
		this.details = d;
	}

}	// End-of Measure

enum ORTHOMEASURETYPES {
	LOCATION,
	DISTANCE,
	AREA,
	ANGLE
}

const isPolyline = (type) => type === AnnotationType.POLYLINE;
const isCustomMarker = (type) => type === AnnotationType.CUSTOM_MARKER;

const makeAnnotationName = (name, type) => {
	return `${name}`
}

const makeAnnotationContent = (name, details, id, type, points, info?) => {
	let baseAnnotationContent = isEmpty(points) ? `` : `
		<div class="annotation-description-group">
			<div class="annotation-group-title">
				<img class="toolbar_item_icon" src="${ isPolyline(type) ? "assets/icons/link.svg" : "assets/icons/marker.svg" }">
				${makeAnnotationName(name, type)}
			</div>
		</div>`

	if (details) baseAnnotationContent = baseAnnotationContent.concat( `
		<mat-divider></mat-divider>
		<div class="annotation-description-group">
			<div class="annotation-group-header">
				Details
			</div>
			${ details }
		</div>` )

	if (info?.area) baseAnnotationContent = baseAnnotationContent.concat(`
		<mat-divider></mat-divider>
		<div class="annotation-description-group-inline">
			<div class="annotation-group-header">
				Area
			</div>
			<span>
				${ info.area } m<sup>2</sup>
			</span>
		</div>` );

	return baseAnnotationContent;
}

const makeAnnotation = ( name, details, id, type, points) => {
	let elTitle = ``;
	if(!isCustomMarker(type))
		elTitle = `<img class="toolbar_item_icon" src="${ isPolyline(type) ? "assets/icons/link.svg" : "assets/icons/marker.svg" }">`;

	if(name.length) {
		elTitle = elTitle.concat( `
			<span class="H6Black700Regular">
				${ name }
			</span>
		` );
	}
	return `
			<div id="anno-titlebar-${ id }" class="annotation-titlebar">
				<span class="annotation-label">
					${ elTitle }
				</span>
			</div>`;
			/* removing the on hover details for now since they are big and dont show much - nic
			<div class="annotation-description">
				<div class="annotation-description-content">
					${ makeAnnotationContent(name, details, id, type, points) }
				</div>
			</div>`;
			*/
}

/*
* Handles OrthoMeasurements
* Point, Distance, Area, Angle
*/
export class OrthoMeasure extends Measure {

	/* Reference */
	public markers: Array<any>;
	public polyline: any;
	public polygon: any;
	public annotation: any;
	public hasAnnotation: any;
	/* Fires when changed */
	public change: BehaviorSubject<any>;
	public measuring: boolean;
	public measurementsLayer = L.layerGroup();
	/* Map */
	public map: any;
	/* Ghost */
	public ghostMarker: any;
	/* Style */
	private _markerIcon: any;
	private _markerIcon_selected: any;
	private _ghostPolyline: any;
	private _ghostPolygon: any;

	constructor( map, params: any = {} ) {
		super( params[ "points" ], params );
		Object.assign( this, params );

		this.map = map;
		//this._markerIcon = params['markerIcon'] != null ? params['markerIcon'] : L.icon({ iconUrl: './assets/icons/point.svg', iconSize: [32, 32], iconAnchor: [15, 16], popupAnchor:[-3, -76] });
		this._markerIcon = L.divIcon( {
			className: "measurement-dot-container",
			html: "<div class=\"measurement-dot\"></div>",
			iconAnchor: [ 17, 16 ]
		} );
		this.setup();
	}	// End-of constructor

	static isOrthoMeasurement( m ) { // for use by the app before we know what type of measurement a point belongs to
		const points = m.data?.points ?? [];
		const point = points[ 0 ];
		return !isEmpty( point ) && "lat" in point && "lng" in point && !( "alt" in point ); // ortho view uses latitude and longitude, where as the point(3d) view uses x,y,z
	}

	hide() {
		this.stop();
		this.markers.forEach( marker => {
			if ( marker.angle ) {
				this.measurementsLayer.removeLayer( marker.angle );
			}
		} );
		this.map.removeLayer( this.measurementsLayer );
	}

	show() {
		this.markers.forEach( marker => {
			if ( marker.angle ) {
				this.measurementsLayer.addLayer( marker.angle );
			}
		} );
		this.map.addLayer( this.measurementsLayer );
		this.deselect();
	}

	makePolyline() {
		return L.polyline( [], {
			color: MeasurementColor.DEFAULT,
			border: "solid 1px white"
		} ).addTo( this.measurementsLayer );
	}

	makePolygon() {
		return L.polygon( [], {
			color: MeasurementColor.DEFAULT,
			border: "solid 1px white"
		} ).addTo( this.measurementsLayer );
	}

	setup(): void {
		this.polyline = this.makePolyline();
		this.polygon = this.makePolygon();
		this.markers = new Array<any>();
		this.change = new BehaviorSubject<any>( {} );

		this.measurementsLayer.addTo( this.map );
		// Events
		this.polyline.on( "click", this.handleMarkerClick, this );
		this.polygon.on( "click", this.handleMarkerClick, this );
		this.polygon.on( "click", (e) => {
			// hacky way to stop event propagation
			this.mapwareViewer.ignoreNextClick = true;
		})

		if ( this.points.length >= 1 ) {
			this.points.forEach( ( point, ind ) => this.renderCoords( point, this.points.length === 1 ) );
			this.update();
		}
	} // End-of setup

	info(): any {
		// Get latlngs from markers
		const latlngs = [];
		this.markers.forEach( m => {
			latlngs.push( m._latlng );
		} );

		if ( this.ghostMarker ) {
			latlngs.push( this.ghostMarker._latlng );
		}

		let info = calc( latlngs );
		// Extra Info
		info[ "name" ] = this.name;
		info[ "details" ] = this.details;
		info[ "id" ] = this.id;
		this.extraInfo = info;
		return info;
	}	// End-of info

	start( parms?: any ): void {
		// Start measurement
		if ( this.measuring ) {
			this.stop();
		}
		this.measuring = true;

		this.ghostMarker = L.marker( this.map.getCenter(),
			{
				icon: this._markerIcon,
				clickable: true,
				opacity: 0
			} ).addTo( this.map );

		/* Ghost Actions */
		this.ghostMarker.on( "click", ( e ) => {
			this.add( e.latlng );
		}, this );
		this.ghostMarker.on( "contextmenu", ( e ) => {
			this.stop();
		}, this );

		// Ghost Polyline
		this._ghostPolyline = L.polyline( [], { color: MeasurementColor.SELECTED, opacity: 0 } ).addTo( this.map );

		// Add map listeners
		this.map.on( "mousemove", this.handleMapMouseMove, this );
	}	// End-of start

	stop(hardStop: boolean = true): void {
		if(this.id) {
			this.pendingAction = PendingActionType.UPDATE;
		} else {
			this.pendingAction = PendingActionType.CREATE;
			this.pendingRequest?.then(() => {
				this.updateAnnotation();
			})
		}
		// Stop measurement
		if ( !this.measuring ) {
			return;
		}
		this.measuring = false;
		// Remove map Listeners
		this.map.off( "mousemove", this.handleMapMouseMove, this );

		// Ghost Marker
		if ( this.ghostMarker ) {
			this.map.removeLayer( this.ghostMarker );
			this.ghostMarker = null;
		}

		// Ghost Polyline
		if ( this._ghostPolyline ) {
			this.map.removeLayer( this._ghostPolyline );
			this._ghostPolyline = null;
		}

		// Ghost Polyline
		if ( this._ghostPolygon ) {
			this.map.removeLayer( this._ghostPolygon );
			this._ghostPolygon = null;
		}

		if ( hardStop ) this.mapwareViewer.stopMeasure(this);
	}	// End-of end

	update(): void {
		if ( this.markers.length === 0 ) {
			this.change.next( { info: this.info(), ref: this } );
			this.points = [];
			return;
		}

		let latlngs = [];
		let turf_points = [];
		this.markers.forEach( m => {
			latlngs.push( m._latlng );
			turf_points.push( turf.point( [ m._latlng.lat, m._latlng.lng ] ) );

			if ( this.showAngles && this.markers.length >= 2 ) {
				this.getAngle( m );
			}
		} );
		this.points = latlngs;

		let centroid = turf.centroid( turf.featureCollection( turf_points ) );

		if ( this.showEdges && this.points.length === 2 ) {
			if (!this.polyline) {
				this.polyline = this.makePolyline();
			}

			if ( this.closed && latlngs.length >= 2 ) {
				let ar = [ ...latlngs, latlngs[ 0 ] ];
				this.polyline.setLatLngs( ar );
			} else {
				this.polyline.setLatLngs( latlngs );
			}
		} else if (this.polyline) {
			this.polyline.remove();
			this.polyline = null;
		}

		if ( this.points.length > 2 ) {
			if (!this.polygon) {
				this.polygon = this.makePolygon();
			}

			if ( !this.showArea ) {
				this.polygon.setOpacity( 0 );
			}
			this.polygon.setLatLngs( latlngs );
		} else if ( this.polygon ) {
			this.polygon.remove();
			this.polygon = null;
		}

		if ( !this.hasAnnotation ) {
			this.addAnnotation( centroid );
		} else {
			this.updateAnnotationCoords( centroid );
		}

		this.change.next( { info: this.info(), ref: this } );

	}	// End-of update

	// noinspection JSVoidFunctionReturnValueUsed
	addAnnotation( centroid, name = this.name ) {
		this.hasAnnotation = true;
		const coords = centroid?.geometry?.coordinates;

		// TODO: @remove new-annotations
		if ( coords && flagLayer.isEnabled(availableFeatureFlags.newAnnotations) ) {
			this.annotation = L.tooltip( {
				className: "annotation-ortho",
				interactive: true,
				permanent: true,
				offset: [ 0, -8],
				direction: "top",
			} )
				.setLatLng( coords )
				.addTo( this.map );

			this.updateAnnotation();

			$(this.annotation.getElement())?.on( "click", (e) => {
				this.change.next( { info: this.info(), ref: this } );
				e.stopPropagation();
			} );
		}
	}

	updateAnnotation( annotation = this.annotation ) {
		const baseAnnotation = makeAnnotation( this.name, this.details, this.id, this.type, this.points );
		annotation?.setContent(baseAnnotation)
	}

	updateAnnotationCoords( centroid ): void {
		const coords = centroid?.geometry?.coordinates;
		this.annotation?.setLatLng( coords );
	}

	removeAnnotation( boundTo = this.getBoundElement() ) {
		boundTo?.unbindTooltip();
	}

	toggleAnno(showAnnotation) {
		if(showAnnotation)
			this.annotation.addTo( this.map )
		else
			this.map.closeTooltip(this.annotation);
	}

	add( isSetup, ...coords ): void {
		if ( typeof isSetup === "object" ) coords.unshift( isSetup );
		coords.forEach( ( coord, ind ) => {
			this.points.push( coord );
			this.renderCoords( coord, this.points.length === 0 );
		} );

		// Honor thy limits
		if ( this.maxMarkers <= this.points.length ) {
			this.stop();
		}

		if ( !isSetup ) this.pendingAction = PendingActionType.UPDATE;
		this.update();
	}	// End-of add

	remove( ...markers ): void {
		markers.forEach( marker => {
			let index = this.markers.findIndex( m => {
				if ( m.angle ) {
					this.measurementsLayer.removeLayer( m.angle );
				}
				return isEqual( m, marker );
			} );

			if ( index >= 0 ) {
				this.markers.splice( index, 1 );
				this.measurementsLayer.removeLayer( marker );
			}
		} );

		this.update();

		if ( !this.markers.length ) {
			this.clear();
		} else {
			this.pendingAction = PendingActionType.UPDATE;
		}
	}	// End-of remove

	clear(): void {
		this.stop();

		// Markers
		this.markers.forEach( mark => {
			if ( mark.angle ) {
				this.map.removeLayer( mark.angle );
			}
			this.map.removeLayer( mark );
		} );
		this.markers = new Array<any>();

		// Polyline
		if (this.polyline) {
			this.polyline.remove();
			this.polyline = null;
		}

		// Polygon
		if (this.polygon) {
			this.polygon.remove();
			this.polygon = null;
		}

		if (this.annotation) {
			this.annotation.remove();
			this.annotation = null;
		}

		this.pendingAction = PendingActionType.DELETE;

		this.points = [];
		this.mapwareViewer.stopMeasure(this);
	}	// End-of clear

	select(): void {
		this.selected = true;
		this.polygon?.setStyle( { color: MeasurementColor.SELECTED, border: "solid 1px white" } );
		this.polyline?.setStyle( { color: MeasurementColor.SELECTED, border: "solid 1px white" } );
		this.markers?.forEach( mark => {
			if(mark._icon.firstChild)
				mark._icon.firstChild.style.backgroundColor = MeasurementColor.SELECTED;
		} );
	}

	deselect(): void {
		this.selected = false;
		this.polygon?.setStyle( { color: MeasurementColor.DEFAULT, border: "solid 1px white" } );
		this.polyline?.setStyle( { color: MeasurementColor.DEFAULT, border: "solid 1px white" } );
		this.markers?.forEach( mark => {
			if(mark._icon.firstChild)
				mark._icon.firstChild.style.backgroundColor = MeasurementColor.DEFAULT;
		} );
	}

	create(): void {
		this.pendingAction = PendingActionType.CREATE;
		this.pendingRequest?.then(() => {
			this.updateAnnotation();
		})
	}

	focus(): void {
		if(this.points && this.points.length > 0) {
			this.map.panTo(this.points[0], {animate: false})
			this.mapwareViewer._mapRenderer.selectAnnotation(this);
		}
	}

	place(): void {
		this.start();
	}

	// Map
	handleMapMouseMove( e ): void {
		if ( this.ghostMarker ) {
			this.ghostMarker.setOpacity( 1 );
			this.ghostMarker.setLatLng( e.latlng );

			if ( this.markers && this.markers.length >= 1 ) {
				let m = this.markers;
				let marker_index = m.length - 1;
				let a = m[ marker_index ]._latlng;
				let coord = [ [ a.lat, a.lng ], [ e.latlng.lat, e.latlng.lng ] ];

				// Polyline
				if ( this.showEdges ) {
					this._ghostPolyline.setStyle( { opacity: 0.75 } );
					this._ghostPolyline.setLatLngs( coord );
				}
			}
		}
		this.update();
	}	// End-of handleMouseMove

	handleMarkerDrag( e ): void {
		this.update();
	}	// End-of handleMarkerDrag

	getBoundElement() {
		return ( this.points.length === 2 ) ? this.polyline : this.polygon;
	}

	handleMarkerDragStart( e ): void {
		e.target.setOpacity( 0.50 );
		this.removeAnnotation();
	}	// End-of handleMarkerDragStart

	handleMarkerDragEnd( e ): void {
		e.target.setOpacity( 1 );
		this.update();
		this.pendingAction = PendingActionType.UPDATE;
	}	// End-of handleMarkerDragEnd

	handleMarkerClick( e ): void {
		this.change.next( { info: this.info(), ref: this } );

		// Add sphere
		// let circle = L.circle(e.target._latlng, {radius: 10}).addTo(this.map);

		if ( this.points.length > 1 ) {
			this.getAngle( e.target );
		}
	}	// End-of handleMarkerClick

	handleMarkerContextMenu( e ): void {
		this.remove( e.target );
	}	// End-of handleMarkerContextMenu

	getAngle( marker ) {
		if ( this.markers.length <= 2 ) {
			return;
		}

		let index = this.markers.findIndex( m => {
			return m == marker;
		} );

		if ( !isNil( index ) && index >= 0 ) {
			let first = this.markers[ ( index + ( this.markers.length - 1 ) ) % this.markers.length ]._latlng;
			let corner = this.markers[ index ]._latlng;
			let second = this.markers[ ( index + ( this.markers.length + 1 ) ) % this.markers.length ]._latlng;

			let p1 = turf.point( [ first.lat, first.lng ] );
			let p2 = turf.point( [ corner.lat, corner.lng ] );
			let p3 = turf.point( [ second.lat, second.lng ] );

			let midPoint = turf.midpoint( p1, p3 );
			let lerpedAngleCoord = this.linearInterpolation( midPoint.geometry.coordinates, p2.geometry.coordinates, 0.9 );

			let ang = Math.round( ( angle( p1, p2, p3 ) % 180 ) * 100.0 ) / 100.0;

			// if (ang >= 180) {
			// 	ang = 380.0 - ang;
			// let fir = (index + (this.markers.length-1)) % this.markers.length;
			// let sec = (index + (this.markers.length+1)) % this.markers.length;
			// console.log("Fir: ", fir, index,  sec);
			// }

			let angleIcon = L.divIcon( {
				className: "measure_angle_container",
				html: "<div class='measure_angle_icon'>" + ang + "<span>&#176;</span></div>"
			} );
			let mark = L.marker( lerpedAngleCoord, { icon: angleIcon } ).addTo( this.measurementsLayer );

			if ( this.markers[ index ].angle ) {
				this.measurementsLayer.removeLayer( this.markers[ index ].angle );
			}

			this.markers[ index ].angle = mark;
		}
	}	// End-of getAngle

	getAngleBetweenLines( cornerPoint, point1, point2 ) {

	}	// End-of getAngleBetweenLines

	linearInterpolation( p1, p2, t: number ): any {
		return [ ( ( 1.0 - t ) * p1[ 0 ] + t * p2[ 0 ] ),
			( ( 1.0 - t ) * p1[ 1 ] + t * p2[ 1 ] ) ];
	}	// End-of linearInterpolation

	markAndUpdate(): void {
		this.update();
		this.updateAnnotation();
		this.pendingAction = PendingActionType.UPDATE;
	}

	renderCoords( coord, showAnnotation ) {
		const marker = L.marker( coord, {
			icon: this._markerIcon,
			draggable: this.mapwareViewer._mapRenderer.map.mapPermissions.canAnnotate,
			autoPan: true
		} ).addTo( this.measurementsLayer );
		marker._icon.firstChild.style.backgroundColor = MeasurementColor.DEFAULT;
		// marker.bindPopup( `<span class=\"H6Black700Regular\">${ this.name }</span>` );

		marker.on( "drag", this.handleMarkerDrag, this );
		marker.on( "dragstart", this.handleMarkerDragStart, this );
		marker.on( "dragend", this.handleMarkerDragEnd, this );
		marker.on( "click", this.handleMarkerClick, this );

		if(this.mapwareViewer._mapRenderer.map.mapPermissions.canAnnotate) {
			marker.on( "contextmenu", this.handleMarkerContextMenu, this );
		}

		this.markers.push( marker );
	}	// End-of renderCoords

}	// End-of class OrthoMeasure

/*
* Handles ModelMeasurements
* Point, Distance, Area, Angle
*/
export class ModelMeasure extends Measure {

	/* Libraries */
	public Potree: any;
	public THREE: any;
	/* Reference */
	public measureRef: any;
	/* Fires when changed */
	public change: BehaviorSubject<any>;
	/* Properties */
	public measuring: boolean = false;
	public showEdges: boolean = false;
	public showCoordinates: boolean = false;
	public showDistances: boolean = true;
	public showHeight: boolean = false;
	public showAngles: boolean = false;
	public showArea: boolean = true;
	public closed: boolean = true;
	/* Viewer */
	public viewer: any;
	public annotation: any;
	public hasEventListeners: boolean = false;
	private isSetup = false;

	constructor( viewer, params: any = {} ) {
		super( params[ "points" ], params );
		Object.assign( this, params );

		// Get libs
		this.THREE = window[ "THREE" ];
		this.Potree = window[ "Potree" ];

		this.viewer = viewer;

		if(params.showMeasureInfo) {
			this.showArea = params.showMeasureInfo.showArea;
			this.showDistances = params.showMeasureInfo.showDistances;
		}
		// this.pendingAction = PendingActionType.DELETE;
		this.setup( params );
	}	// End-of constructor

	hide(): void {
		this.measureRef.visible = false;
		// this.annotation
	} 	// End-of hide

	show(): void {
		this.measureRef.visible = true;
	}	// End-of show

	setup( {
				points = this.points,
				showDistances = this.showDistances,
				showArea = this.showArea,
				showAngles = false,
				showHeight = false,
				showEdges = true,
				closed = true
			} = {} ): void {
		this.isSetup = true;
		this.change = new BehaviorSubject<any>( {} );
		if ( points.length >= 1 ) {
			this.measureRef = new this.Potree.Measure();
			this.measureRef.color = new THREE.Color( MeasurementColor.DEFAULT );

			this.addEventListeners();

			this.measureRef.showDistances = showDistances;
			this.measureRef.showArea = showArea;
			this.measureRef.showAngles = showAngles;
			// this.measureRef.showCoordinates = pick(args.showCoordinates, false);
			this.measureRef.showHeight = showHeight;
			// this.measureRef.showCircle = pick(args.showCircle, false);
			// this.measureRef.showAzimuth = pick(args.showAzimuth, false);
			this.measureRef.showEdges = showEdges;
			this.measureRef.closed = closed;
			this.measureRef.maxMarkers = this.maxMarkers;
			this.measureRef.name = this.name;
			this.measureRef.created_by_id = this.created_by_id;

			this.viewer.measuringTool?.scene.add( this.measureRef );
			this.viewer.scene?.addMeasurement( this.measureRef );
			this.add( true, ...this.points );
		}

		this.isSetup = false;
	}	// End-of setup

	info(): any {
		let info = {};
		info[ "name" ] = this.name;
		info[ "details" ] = this.details;
		info[ "id" ] = this.id;
		info["coordinate"] = {
			dd: {
				x: 0,
				y: 0
			},
			dms: {
				x: "",
				y: ""
			},
			alt: 0
		}
		if ( this.measureRef && this.viewer?.lengthUnit ) {
			let positions = this.measureRef.points.map( p => p.position );
			if (positions.length === 1) {
				let convertedPoints = this.mapwareViewer.convertToLatLng(this.mapwareViewer.fromPoints(positions[0]));
				info["coordinate"] = convertedPoints.coordinate;
				info["coordinate"].alt = positions[0].z;
			}
			this.measureRef.closed = positions.length > 2;
			info[ "distance" ] = this.measureRef.getTotalDistance();
			info[ "distance" ] = ( info[ "distance" ] && info[ "distance" ] > 0 ) ? info[ "distance" ] : null;

			info[ "face_area" ] = 0;
			const convexGeometry = new ConvexGeometry(this.points)
			let rawPoints = convexGeometry.getAttribute("position").array;
			let convexArea = 0;
			if(rawPoints.length % 9 == 0) {
				for(var i = 0; i < rawPoints.length / 2; i += 9) {
					let triangle = new THREE.Triangle(
						new THREE.Vector3(rawPoints[i], rawPoints[i+1], rawPoints[i+2]),
						new THREE.Vector3(rawPoints[i+3], rawPoints[i+4], rawPoints[i+5]),
						new THREE.Vector3(rawPoints[i+6], rawPoints[i+7], rawPoints[i+8]));
					convexArea += triangle.getArea();
				}
				info["face_area"] = convexArea;
			}

			info["area"] = this.measureRef.getArea();
			info[ "area" ] = ( info[ "area" ] && info[ "area" ] > 0 ) ? info[ "area" ] : null;
		}
		this.extraInfo = info;

		return info;
	}	// End-of info

	start(): void {
		if ( !this.measureRef ) {
			this.measureRef = this.viewer.measuringTool.startInsertion( {
				showCoordinates: this.showCoordinates,
				showDistances: this.showDistances,
				showHeight: this.showHeight,
				showAngles: this.showAngles,
				showArea: this.showArea,
				closed: this.closed,
				maxMarkers: this.maxMarkers,
				color: MeasurementColor.DEFAULT,
				name: this.name ? this.name : "Measure",
				created_by_id: this.created_by_id
			} );

			this.addEventListeners();
		} else {
			this.measureRef = this.viewer.measuringTool.setupInsertion( this.measureRef );
		}

		let cancel = ( e ) => {
			if ( e.measure && e.measure.uuid === this.measureRef.uuid && this.measuring) {
				this.measuring = false;
				this.viewer.removeEventListener( "cancel_insertions", cancel );
				this.update();

				// a softstop would be for when we want to stop the marker placement, but we dont want to deselect the Annotation
				if(!e.softStop) this.mapwareViewer.stopMeasure(this);
			}
		};
		this.viewer.addEventListener( "cancel_insertions", ( e ) => {
			cancel( e );
		} );

		this.measuring = true;
	}	// End-of start

	stop(hardStop: boolean = true): void {
		if ( !this.measuring ) {
			return;
		}

		this.change.next( { info: null, ref: this } );

		this.viewer.dispatchEvent({
			type: 'cancel_insertions',
			measure: this.measureRef,
			softStop: !hardStop
		});

		if (!this.id && !this.pendingRequest) {
			this.clear(true);
		}
	}	// End-of stop

	update( updateAnnotation = true ): void {
		if ( this.measureRef?.points ) {
			this.points = this.measureRef.points.map( x => x.position );
			this.change.next( { info: this.info(), ref: this } );
		}

		// TODO: @remove new-annotations
		if(flagLayer.isEnabled(availableFeatureFlags.newAnnotations)) {
			if ( updateAnnotation ) {

				if ( !isEmpty( this.annotation ) ) this.removeAnnotationFromMap( this.annotation );
				this.addAnnotationToMap();
			}
		}
	}	// End-of update

	add( isSetup, ...positions ): void {
		if ( typeof isSetup === "object" ) positions.unshift( isSetup );
		positions.forEach( pos => {
			// Honor thy limits
			if ( this.maxMarkers <= this.points.length ) {
				return;
			}
			if ( !isEmpty( pos ) )
				this.measureRef.addMarker( new THREE.Vector3( pos.x, pos.y, pos.z ), this.mapwareViewer._mapRenderer.map.mapPermissions.canAnnotate);
		} );

		if ( this.maxMarkers <= this.points.length ) {
			this.stop();
		}
		if ( !isSetup ) {
			this.pendingAction = PendingActionType.UPDATE;
		} else {
			this.pendingAction = PendingActionType.NONE;
		}
		this.update();
	}	// End-of add

	getCenterPosition( points ): any {
		const nItems = points.length;
		return points.reduce( ( acc, val ) => {
			Object.keys( val ).forEach( key => {
				acc[ key ] += val[ key ] / nItems;
			} );
			return acc;
		}, { x: 0, y: 0, z: 0 } );
	}

	toggleAnno(show) {
		if (show)
			this.viewer.scene.annotations.add( this.annotation );
		else
			this.viewer.scene.annotations.remove(this.annotation)
	}

	addAnnotationToMap( points = this.points, name = this.name, details = this.details ) {
		const originPoint = points.length > 1 ? this.getCenterPosition( points ) : points[ 0 ];
		if ( originPoint && points?.length ) {
			let elTitle = `<img class="toolbar_item_icon" src="${ isPolyline(this.type) ? "assets/icons/link.svg" : "assets/icons/marker.svg" }">`;
			if(name.length) {
				elTitle = elTitle.concat( `
					<span class="H6Black700Regular">
						${ name }
					</span>
						` );
			}

			const annotation = new Potree.Annotation( {
				position: [ originPoint.x, originPoint.y, originPoint.z ],
				// Can even be used to create custom views and angles
				// cameraPosition: [ originPoint.x + 40, originPoint.y + 40, originPoint.z + 40 ],
				// cameraTarget: [ originPoint.x, originPoint.y, originPoint.z ],
				title: $(elTitle),
				//description: makeAnnotationContent( name, details, this.id, this.type, this.points, this.info() )
			} );

			annotation.addEventListener( "click", ( e ) => {
				this.update( false );
			} );

			if ( !isEqual( annotation, this.annotation ) ) {
				this.annotation = annotation;
				this.viewer.scene.annotations.add( annotation );
			}
		}
	}

	removeAnnotationFromMap( annotation = this.annotation ) {
		if ( annotation ) {
			this.viewer.scene.annotations.remove( annotation );
			this.annotation = null;
		}
	}

	remove( ...markers ): void {
		markers.forEach( marker => {
			// Removes marker by index
			this.measureRef.removeMarker( marker );
		} );

		this.update();
		this.pendingAction = this.points.length ? PendingActionType.UPDATE : PendingActionType.DELETE;
	}	// End-of remove

	clear(fromStop = false): void {

		this.removeEventListeners();
		this.removeAnnotationFromMap();
		this.viewer.scene.removeMeasurement( this.measureRef );

		// Queue up deletion action
		this.pendingAction = PendingActionType.DELETE;

		this.points = [];
		this.mapwareViewer.stopMeasure(this);
	}	// End-of clear

	select(): void {
		if ( !this.selected ) {
			// console.log("selecting")
			this.selected = true;
			this.measureRef.color = new THREE.Color( MeasurementColor.SELECTED );
		}
	}

	deselect(): void {
		if ( this.selected && this.measureRef ) {
			// console.log("deselecting")
			this.selected = false;
			this.measureRef.color = new THREE.Color( MeasurementColor.DEFAULT );
		}
	}

	onMarkerAdded = () => {
		// console.log("onMarkerAdded") // Left for debugging
		// Create annotation on first marker drop
		if ( !(this.pendingAction === PendingActionType.CREATE || this.id) ) {
			this.pendingAction = PendingActionType.CREATE;
			this.change.next( { info: this.info(), ref: this } );
		}
		if (this.points.length >= this.maxMarkers) this.stop();
		this.onMarkerDropped();
	}

	onMarkerDropped = () => {
		// console.log("onMarkerDropped") // Left for debugging
		if (!this.hasEventListeners) {
			this.addEventListeners();
		}
		this.update();
	
		this.pendingAction = isNil( this.id ) ? PendingActionType.CREATE : 
			!this.isSetup ? PendingActionType.UPDATE: PendingActionType.NONE;

		if(this.points.length >= this.maxMarkers)
			this.stop();
	}

	onMarkerRemoved = () => {
		// console.log("onMarkerRemoved") // Left for debugging
		this.update();
		if ( this.points.length >= 1 ) {
			this.markAndUpdate();
		} else {
			this.clear();
		}
	}

	onMarkerMoved = () => {
		// console.log("onMarkerMoved") // Left for debugging
		this.removeAnnotationFromMap();
		this.update( false );
	}

	/* Handles */
	addEventListeners(): void {
		// Condense listeners into one later (rather then adding them twice)
		if (this.measureRef) {
			this.hasEventListeners = true;
			this.measureRef.addEventListener( "marker_added", this.onMarkerAdded );
			this.measureRef.addEventListener( "marker_dropped", this.onMarkerDropped );
			this.measureRef.addEventListener( "marker_removed", this.onMarkerRemoved );
			this.measureRef.addEventListener( "marker_moved", this.onMarkerMoved );
		}
	}

	removeEventListeners(): void {
		if (this.measureRef) {
			this.measureRef.removeEventListener( "marker_added", this.onMarkerAdded );
			this.measureRef.removeEventListener( "marker_dropped", this.onMarkerDropped );
			this.measureRef.removeEventListener( "marker_removed", this.onMarkerRemoved );
			this.measureRef.removeEventListener( "marker_moved", this.onMarkerMoved );
			this.hasEventListeners = false;
		}
	}

	markAndUpdate(): void {
		this.update();
		this.pendingAction = PendingActionType.UPDATE;
	}

	// TODO @remove - temp-team-measurements
	toggleLabels( labels ): void {
		Object.keys( labels ).forEach( ( key ) => {
			const val =
				this?.measureRef?.[ "_" + key ] ??
				this?.measureRef?.[
				"_show" + key.charAt( 0 ).toUpperCase() + key.slice( 1 )
					];
			if ( val !== undefined ) this.measureRef[ "_" + key ] = !val;
		} );
	}

	setLabelVisibility( label: string, visibilty: boolean ): void {
		if ( this?.measureRef?.hasOwnProperty( label ) ) {
			this.measureRef[ label ] = visibilty;
		} else {
			// console.warn( `Measure: Attempted to set label visibility for non-existing label: ${ label }` );
		}
	}
}
