
import {Injectable} from "@angular/core";
import {HttpService} from "./http.service";
import {ChargebeePlan} from "@shared/models/chargebee-plan.model";

export interface StripeCheckout {
	line_items: any[];
	success_url: string;
	cancel_url: string;
	customer: number;
}

export interface StripeUpdate {
	"item_id": number,
	"price_id": string
}

export interface StripePortal {
	return_url: string;
	customer: number;
}

@Injectable()
export class FinanceService {

	constructor(
		private httpService: HttpService,
	) {
	}	// End-of constructor

	createCheckout(checkoutOptions: StripeCheckout): Promise<any> {
		const url = "/stripe/checkout";
		return this.httpService.post(url, checkoutOptions)
	}

	updatePlan(checkoutOptions: StripeUpdate): Promise<any> {
		const url = "/stripe/update_plan";
		return this.httpService.put(url, checkoutOptions)
	}

	createPortal(portalOptions: StripePortal): Promise<any> {
		const url = "/stripe/portal";
		return this.httpService.post(url, portalOptions)
	}

	getPriceById(id: number): Promise<any> {

		const url = `/stripe/price/${id}`;
		return this.httpService.get(url);

	}

	getProducts(): Promise<any> {

		const url = `/stripe/products`;
		return this.httpService.get(url);

	}

	getProductById(id: number): Promise<any> {

		const url = `/stripe/products/${id}`;
		return this.httpService.get(url);

	}

	getSubscriptionById(id: number): Promise<any> {

		const url = `/stripe/subscriptions/${id}`;
		return this.httpService.get(url);

	}

	getSubscriptionItemById(id: number): Promise<any> {

		const url = `/stripe/subscriptions/item/${id}`;
		return this.httpService.get(url);

	}

	getPlans(): Promise<any> {

		const url = `/stripe/plans`;
		return this.httpService.get(url);

	}

	getChargeBeePlans(): Promise<ChargebeePlan[]> {
		return this.httpService.get('/chargebee/plans');
	}
}
