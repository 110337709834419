// A-Z Sort
export const availableFeatureFlags = {
	apiUrl: "api-url",
	apiV2Routes: "temp-api-v2-routes",
	apiV2Images: "temp-api-v2-image-upload",
	checkForExportsDisabled: "temp-check-for-exports-disabled",
	createBatches: "temp-create-batches",
	createProcessOrder: "temp-create-process-order",
	devTesting: "dev-testing",
	disableExports: "disable-exports",
	disableGCPs: "disable-gcps",
	disableInvites: "disable-invites",
	disableMapware: "disable-mapware",
	disableModifyPermissions: "disable-modify-permissions",
	disableOnboarding: "disable-onboarding",
	disablePayments: "disable-payments",
	disableProcessing: "disable-processing",
	disableProcessingForMaintenance: "disable-processing-for-maintenance",
	disableImportsForMaintenance: "disable-imports-for-maintenance",
	disableQuestionnaire: "disable-questionnaire",
	disableRemoveUser: "disable-remove-user",
	disableSharing: "disable-sharing",
	disableSignup: "disable-signup",
	disableViewer: "disable-viewer",
	enableShapeFileImport: "temp-enable-shapefile-import",
	extendGeoTIFFBoundaries: "temp-extend-geotiff-boundaries",
	folderUpload: "folder-upload",
	hideCredits: "hide-credits",
	iiqImageType: "temp-iiq-image-type",
	maintenanceBanner: "maintenance-banner",
	mapwareFlyPromo: "mapware-fly-promo",
	moveImages: "temp-move-images",
	newAnnotations: "new-annotations",
	newSubscription: "temp-new-subscription",
	newUpload: "temp-new-upload",
	addLayerFromMap: "temp-map-add-layer",
	processesPage: "temp-processes-page",
	processesTimeRemaining: "processes-time-remaining",
	removeSubscriptionRequirement: "remove-subscription-required",
	separateModelProcessUpload: "separate-model-process-upload",
	supportAdditionalImportTypes: "temp-support-additional-import-types",
	teamMeasurements: "temp-team-measurements",
	upgradedAPItoAzureURL: "temp-upgrade-api-to-azure-url",
	viewerLayers: "viewer-layers",
	mlWizard: "ml-wizard",
	projectViewExportLayers: "temp-project-view-export-layers",
	tempMapReactLayerControls: "temp-map-react-layer-controls",
	mapModeState: "temp-map-mode-state",
	elevationView: "temp-elevation-view",
	exportEPSG: "temp-export-epsg",
	multiSpectral: "temp-multi-spectral",
	systemUseBanner: "system-use-banner",
	showUploadMinimap: "show-upload-minimap",
	filteredImages: "temp-filtered-images",
	processODM: "process-odm",
	whereAmI: "temp-where-am-i",
	aiModelList: "temp-ai-model-list",
	aiInferencing: "temp-ai-inferencing",
	newProcess: "temp-new-process",
	aiResults: "temp-ai-kml-results",
	contour: "temp-contour",
	markerImport: "temp-marker-import",
};
