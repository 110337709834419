
import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import {cloneDeep, debounce, isNil, keyBy, uniqBy} from 'lodash';
import {AlertService, byId, FavoriteService, ImageService, UploadService} from '@shared/services';
import { toAPIFormat } from '@app/pages/projects';
import { sortByNewestToOldest, sortByOldestToNewest, sortName, sortNameReversed } from '@shared/services';
import moment from 'moment/moment';
import { flagLayer, availableFeatureFlags } from "@shared/featureFlags";
import pluralize from "pluralize";
import {Alert, Image} from "@shared/models";
import {MoveImagesDialog} from "@app/components";
import {first} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";

const imagesLimit = 100;
const endBuffer = Math.round(imagesLimit / 3);

type ListType = "list" | "small" | "large";

const sortValue = (value) => (a, b) => (b[value] - a[value]);
const sortValueReversed = (value) => (a, b) => (a[value] - b[value]);

@Component({
	selector: 'app-images',
	templateUrl: './images.component.html',
	styleUrls: ['./images.component.scss']
})
export class ImagesComponent {

	@ViewChild('scroll') scrollElem: any;

	@Input('imageGroup') set inImageGroup(imageGroup) {
		if (!isNil(imageGroup?.id)) {
			if (this.imageGroup?.id !== imageGroup.id) this.clearAll();
			this.imageGroup = imageGroup;
			this.getImageGroupImages(imageGroup);
		}
	}
	@Input("imagesChange") set imagesChange(_) {
		this.getImageGroupImages();
	}

	@Input() showInfo: boolean = false;
	@Input() disableMove: boolean = false;
	@Input() hasPermissions;
	@Input() listType: ListType = "list";

	@Output('outEvent') outEventEmitter: EventEmitter<any> = new EventEmitter();

	public imageGroup;
	public uploadImages = [];
	public uploadedImageIds = {};
	public images = [];
	public hasScroll;
	public scrollPosition = 0;
	public getImagesPromise: Promise<any> = null;
	public imagesOffset = 0;
	public isLoading = true;
	public projectListSubscription: any;
	public canGetMoreImages: boolean = true;
	public plural = pluralize;

	public sort: "name" | "created_at" | "size" | "pixels" = "created_at";
	public sortDirectionUp: boolean = false; // "up" starts the 'smallest' value at the beginning of the list
	// e.g. ["hall", "ceiling", "floor"] with a "name" sort, "up" sorts to -> ["ceiling", "floor", "hall"]

	public sortOptions = [
		{ text: "Name", value: "name" },
		{ text: "Date", value: "created_at" },
		{ text: "Size", value: "size" },
		{ text: "Status" },
		{ text: "Megapixels" },
		{ text: "(Lat, Long)" },
		{ text: "Resolution" },
	]

	public failedUploadItems = [];
	public uploadingItems = [];
	public completedItems = [];
	public percentageComplete = 0;

	constructor(
		private _alertService: AlertService,
		private _favoriteService: FavoriteService,
		private _imageService: ImageService,
		private _uploadService: UploadService,
		private _dialog: MatDialog,
	) {

		this.projectListSubscription = this._uploadService.projectListChange.subscribe(({ renderState }) => {
			this.trackAndUpdateUploads(renderState);
		});

	}

	clearAll() {
		this.getImagesPromise = null;
		this.images = [];
		this.isLoading = true;
		this.imagesOffset = 0;
		this.canGetMoreImages = true;
	}

	trackAndUpdateUploads(renderState) {

		const project = this.imageGroup?.project_id ? renderState.projectList?.find(byId(this.imageGroup.project_id)) : null;

		if (project?.files?.length && this.imageGroup?.id) {
			const imageList = project.files.filter(x =>
				!x.trash &&
				x.uploadType === "Image" &&
				x.status !== "SUCCESS" &&
				this.imageGroup.id === x.batchId
			);
			if (imageList?.length) {
				imageList.forEach(file => {
					if (!file.created_at) {
						file.created_at = toAPIFormat(moment().utc()); // UTC time to match API
					}
				});
				this.uploadImages = this.sortUploadImages(imageList);
				this.updateUpload(this.uploadImages);
				this.canGetMoreImages = !!this.uploadImages.length;
			} else {
				if (this.uploadImages?.length) {
					this.clearAndUpdate();
				}
			}
		}
	}

	updateUpload(uploadImages) {

		this.failedUploadItems = uploadImages?.filter(x => x.status === "FAILURE") ?? [];
		this.uploadingItems = uploadImages?.filter(x => x.status === "ACTIVE") ?? [];
		this.completedItems = uploadImages?.filter(x => x.status === "SUCCESS") ?? [];
		this.percentageComplete = this.getPercentageComplete(uploadImages);

		if (this.uploadingItems?.length <= 0) {
			this.clearAndUpdate();
		}

	}

	clearUpload() {

		this.failedUploadItems = [];
		this.uploadingItems = [];
		this.completedItems = [];
		this.percentageComplete = 0;
	}

	getImageGroupImages(imageGroup = this.imageGroup) {
		if (!this.getImagesPromise && this.canGetMoreImages && imageGroup?.id) {
			this.isLoading = true;
			const dir = this.sortDirectionUp ? "asc" : "desc";
			const batchFunc = flagLayer.isEnabled(availableFeatureFlags.filteredImages) ?
				this._imageService.getBatchImagesV2(imageGroup.id, imagesLimit, this.imagesOffset, this.sort, dir) :
				this._imageService.getBatchV2(imageGroup.id);

			this.getImagesPromise = batchFunc.then(rtn => {
				const groupImages = flagLayer.isEnabled(availableFeatureFlags.filteredImages) ?
					rtn :
					rtn.images;

				if (groupImages?.length) {
					this.imagesOffset += imagesLimit;
					this.filterAndUpdateImages(groupImages);
				}
				this.canGetMoreImages = groupImages?.length === imagesLimit;
				this.isLoading = false;
				this.getImagesPromise = null;
			}).catch(err => {
				console.error(err);
				this.isLoading = false;
				this.getImagesPromise = null;
			})
		}
	}

	getFavorites(inImages: any[]): void {

		this._favoriteService.getList().then(({images = []}) => {
			if (images?.length && inImages?.length) {
				const imageFavoriteIdPairs = images.reduce((acc, imageFav) => {
					Object.assign(acc, { [imageFav.image_id] : imageFav.id });
					return acc;
				}, {});
				inImages.forEach(image => {
					if (imageFavoriteIdPairs[image.id]) {
						image.favorited = true;
						image.favorited_id = imageFavoriteIdPairs[image.id];
					}
				})
			}
		});
	}

	extractMultispecImages(inImages) {

		const {outImages, multispec} = inImages.reduce((acc, x) => {
			if (x.source_image_id) {
				if (acc.multispec[x.source_image_id]) {
					acc.multispec[x.source_image_id].push(x);
				} else {
					acc.multispec[x.source_image_id] = [x];
				}
			} else {
				acc.outImages.push(x);
			}
			return acc;
		}, { outImages: [], multispec: {}});

		return outImages.map(image => Object.assign(image, { bands: multispec[image?.id] ?? [] }));
	}

	sortUploadImages(inImages): Array<any> {
		if (inImages?.length) {
			const sortUp = this.sortDirectionUp;
			const MATCH_KEY = true as any;
			const matcher = {
				[MATCH_KEY]: sortByNewestToOldest,
				[(this.sort === "created_at") && !sortUp as any]: sortByOldestToNewest,
				[(this.sort === "name") && sortUp as any]: sortName,
				[(this.sort === "name") && !sortUp as any]: sortNameReversed,
				[((this.sort === "size") || (this.sort === "pixels")) && sortUp as any]: sortValue(this.sort),
				[((this.sort === "size") || (this.sort === "pixels")) && !sortUp as any]: sortValueReversed(this.sort),
			};
			return inImages.sort(matcher[MATCH_KEY]);
		}
		return inImages;
	}

	filterAndUpdateImages(inImages = []) {

		const mergedArray = [ ...this.images, ...inImages ];
		const uniqueArray = uniqBy(mergedArray, "id");
		const extractedImages = this.extractMultispecImages(uniqueArray);
		const sortImages = extractedImages?.length ? extractedImages : this.images;
		const resortedImages = this.sortUploadImages(sortImages);

		if (sortImages?.length) {
			this.images = resortedImages.filter(x => x.active && !x.trash);
			this.updateIndex();
			this.imageGroup.images = this.images;
			this.isLoading = false;

			if (this.scrollPosition && this.scrollElem) {
				setTimeout(() => {
					this.scrollElem.scrollToPosition(this.scrollPosition);
				}, 250);
			}

			this.getFavorites(this.images);
		}
	}

	updateIndex() {
		this.images.forEach((image, ind) => image.index = ind);
	}

	clearAndUpdate() {
		this.clearAll();
		this.getImageGroupImages(this.imageGroup);
		this.uploadImages = [];
	}

	setSort(sort) {
		if (this.isSort(sort)) {
			this.toggleSortDirection();
		} else {
			this.sort = sort;
			this.sortDirectionUp = true;
			this.clearAndUpdate();
		}
	}

	toggleSortDirection() {
		this.sortDirectionUp = !this.sortDirectionUp;
		this.clearAndUpdate()
	}

	onVSStart(e) {
		this.hasScroll = e?.maxScrollPosition > 0;
	}

	onVSChange(e) {
		this.scrollPosition = e?.scrollStartPosition ?? 0;
		this.hasScroll = e?.maxScrollPosition > 0;
		if ((this.images?.length > endBuffer) && (e?.endIndex > this.images?.length - endBuffer)) {
			this.getImageGroupImages(this.imageGroup);
		}
	}

	outEvent(event): void {
		if (event.type && event.images?.length) {
			const { type, images } = event;
			if (type === "move") this.openMoveItemsDialog(images);
			if (type === "delete-images") this.deleteImages(images);
			if (type === "favorite") this.favoriteImages(images);
			else this.outEventEmitter.emit(event);
		} else if (typeof event === "string") {
			const images = this.images.filter(image => image.selected);
			this.outEvent({type: event, images});
		}
	}

	isSort(sort) {
		return this.sort === sort;
	}

	checkSortAndDir(sort): boolean {
		return !this.isSort(sort) || this.sortDirectionUp;
	}

	setListType(type: ListType) {
		this.listType = type;
	}

	getPercentageComplete(uploadImages): number {
		if (!uploadImages?.length) return 0;
		const totalPercent = uploadImages
			.reduce((acc, image) => {
				acc += image.percentageComplete;
				return acc;
			}, 0);
		return Math.round(totalPercent / uploadImages.length) ?? 0;
	}

	deleteImages(items) {

		items = Array.isArray(items) ? items : [items] // whether it's [ a, b, c ]  or [ z ] we can use all of the same code with negligible if any performance change

		const promises: Array<Promise<Image>> = items.map(item =>
			this._imageService.trash(new Image({ guid: item.guid }))
		)

		Promise.all(promises)
			.then(() => {
				this.removeImages(items);
				this._alertService.notify(`${items.length} image${items.length === 1 ? '' : 's'} moved to trash`);
				// this.resetTableSelection();
			})
			.catch(error => {
				switch (error) {
					case 402:
						console.error("Failed to remove image: ", error.status, items)
						this._alertService.notify(`Failed to move item${items.length === 1 ? '' : 's'} to trash`)
						break
					default:
						console.warn(error)
						break
				}
			})
	}

	removeImages(selectedPhotos: any[]): void {

		const selectedIds = selectedPhotos.reduce(
			(acc, { id }) => Object.assign(acc, { [id]: true }), {});
		const remainingImages = cloneDeep(this.images);
		this.images = remainingImages.filter(image => {
			return (!selectedIds[image.id])
		});
		this.updateIndex();
	}

	openMoveItemsDialog(selectedImages: Image[]): void {

		const dialogRef = this._dialog.open(MoveImagesDialog, {
			data: {
				fromBatch: this.imageGroup,
				selectedImages
			}
		})
		dialogRef.afterClosed()
			.pipe(first())
			.subscribe(toBatch => {
				if (toBatch?.id) {
					this.moveItems(this.imageGroup, toBatch, selectedImages);
				}
			})
	}

	moveItems(fromBatch, toBatch, selectedImages: Image[]): void {

		this.sendMoveItemsReq(fromBatch, toBatch, selectedImages).then(
			this.handleImageMove(fromBatch)
		).catch(err => {
			console.error(err);
			this._alertService.error(new Alert("Could not move the selected item(s), please try again."))
		});
	}

	sendMoveItemsReq(fromBatch, toBatch, selectedImages) {
		const promises = selectedImages.map((image) => {
			return this._imageService.moveImageToBatch(
				fromBatch.id,
				toBatch.id,
				image
			)
		});
		return Promise.allSettled(promises);
	}

	handleImageMove = (fromBatch) => (resultList) => {

		const {succeeded, failed} = resultList.reduce(
			(acc, res) => {
				const image = JSON.parse(res.value);
				if (res.status === "fulfilled") acc.succeeded = [...acc.succeeded, image];
				else acc.failed = [...acc.failed, image];
				return acc;
			},
			{succeeded: [], failed: []}
		);

		this.removeImages(succeeded);

		if (failed.length === 0) {
			this._alertService.notify(
				`${resultList.length} ${pluralize("image", resultList.length)} moved from ${fromBatch.name}`,
				"success"
			);
		} else {

			const formatter = new (Intl as any).ListFormat("en", {
				style: "long",
				type: "conjunction",
			});

			const formattedImageNames = formatter.format(
				failed.map((img) => img.name)
			);

			this._alertService.notify(
				`The following ${pluralize("image", failed.length)} failed to move from ${fromBatch.name}: ${formattedImageNames}`,
				"error"
			);
		}
	}

	allSelected() {
		return this.images?.every(image => image.selected);
	}

	someSelected() {
		return this.images?.some(image => image.selected);
	}

	onSelectionChange(event, image) {
		let toAlter = this.images?.find(img => img.id === image.id);
		toAlter.selected = event.checked;
	}

	toggleAll() {
		if (this.allSelected()) {
			this.images.map(image => image.selected = false);
		} else {
			this.images.map(image => image.selected = true);
		}
	}

	selectedAreFavorited(images = this.images) {
		return images
			?.filter(image => (image.selected))
			?.every(image => image.favorited);
	}

	favoriteImages(images) {
		const areFavorited = images.every(image => image.favorited);
		Promise.all(images.map(image => {
			return areFavorited ? this.removeFavorite(image) : this.favorite(image);
		})).then(() => {
			const selectedText = images.length > 1 ? "selected " : "";
			const alertText = areFavorited ? `Removed ${selectedText}from Favorites` : `Added ${selectedText}to Favorites`;
			this._alertService.notification(new Alert(alertText));
		})
	}

	async favorite(item) {
		if (item.id && !item.favorited) {
			this._favoriteService.create({image_id: item.id}).then((rtnData) => {
				item.favorited_id = rtnData.favorite_id;
				item.favorited = true;
			});
		}
	}

	async removeFavorite(item) {
		if (item.favorited && item.favorited_id) {
			this._favoriteService.remove(item.favorited_id).then(() => {
				item.favorited = false;
			});
		}
	}
}
