{
	"com": {
		"api-url": false,
		"create-demo-project": true,
		"deprecate-sections-route": false,
		"dev-testing": false,
		"disable-exports": false,
		"disable-gcps": false,
		"disable-invites": false,
		"disable-mapware": false,
		"disable-modify-permissions": false,
		"disable-onboarding": false,
		"disable-payments": false,
		"disable-processing": false,
		"disable-processing-for-maintenance": false,
		"disable-imports-for-maintenance": false,
		"disable-questionnaire": true,
		"disable-remove-user": false,
		"disable-sharing": false,
		"disable-signup": false,
		"disable-viewer": false,
		"folder-upload": false,
		"hide-credits": false,
		"mapware-fly-promo": true,
		"new-annotations": false,
		"remove-subscription-required": true,
		"separate-model-process-upload": false,
		"temp-2d-background-layers": false,
		"temp-api-v2-routes": false,
		"temp-api-v2-image-upload": false,
		"temp-check-for-exports-disabled": false,
		"temp-create-batches": false,
		"temp-create-process-order": false,
		"temp-move-images": false,
		"temp-enable-shapefile-import": false,
		"temp-extend-geotiff-boundaries": false,
		"temp-iiq-image-type": false,
		"temp-processes-page": false,
		"temp-map-add-layer": false,
		"temp-new-subscription": false,
		"temp-new-upload": false,
		"processes-time-remaining": false,
		"temp-support-additional-import-types": false,
		"temp-team-measurements": false,
		"temp-upgrade-api-to-azure-url": false,
		"temp-viewer-details": false,
		"temp-project-view-export-layers": false,
		"viewer-layers": false,
		"ml-wizard": false,
		"temp-map-react-layer-controls": false,
		"temp-map-mode-state": false,
		"temp-elevation-view": false,
		"temp-export-epsg": false,
		"temp-multi-spectral": false,
		"system-use-banner": false,
		"show-upload-minimap": false,
		"temp-filtered-images": false,
		"process-odm": false,
		"temp-where-am-i": false,
		"temp-ai-model-list": false,
		"temp-ai-inferencing": false,
		"temp-new-process": false,
		"temp-ai-kml-results": false,
		"temp-contour": false,
		"temp-marker-import": false
  },
	"gov": {
		"api-url": false,
		"disable-processing-for-maintenance": false,
		"create-demo-project": false,
		"deprecate-sections-route": false,
		"dev-testing": false,
		"disable-exports": true,
		"disable-gcps": true,
		"disable-modify-permissions": true,
		"disable-invites": false,
		"disable-onboarding": true,
		"disable-questionnaire": true,
		"disable-remove-user": true,
		"disable-payments": true,
		"disable-processing": true,
		"disable-signup": true,
		"disable-sharing": false,
		"folder-upload": false,
		"hide-credits": true,
		"mapware-fly-promo": true,
		"new-annotations": false,
		"remove-subscription-required": true,
		"separate-model-process-upload": false,
		"viewer-layers": false,
		"temp-enable-shapefile-import": false,
		"temp-extend-geotiff-boundaries": false,
		"temp-iiq-image-type": false,
		"temp-new-subscription": false,
		"temp-new-upload": false,
		"temp-viewer-details": false,
		"temp-team-measurements": false,
		"temp-check-for-exports-disabled": false,
		"temp-create-batches": false,
		"temp-create-process-order": false,
		"temp-move-images": false,
		"disable-mapware": false,
		"disable-viewer": false,
		"temp-processes-page": false,
		"processes-time-remaining": false,
		"temp-support-additional-import-types": false,
		"temp-upgrade-api-to-azure-url": false,
		"ml-wizard": false,
		"temp-2d-background-layers": false,
		"temp-api-v2-routes": false,
		"temp-api-v2-image-upload": false,
		"temp-project-view-export-layers": false,
		"temp-elevation-view": false,
		"temp-export-epsg": false,
		"temp-multi-spectral": false,
		"system-use-banner": false,
		"show-upload-minimap": false,
		"temp-filtered-images": false,
		"process-odm": false,
		"temp-where-am-i": false,
		"temp-ai-model-list": false,
		"temp-ai-inferencing": false,
		"temp-new-process": false,
		"temp-ai-kml-results": false,
		"temp-contour": false,
		"temp-marker-import": false
  }
}
