
import {Component, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {AlertService, ImageService, byId, UploadService} from '@shared/services';
import {isNil} from 'lodash';
import { sortByNewestToOldest, sortByOldestToNewest } from '@shared/services/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateImageGroupDialog } from '../create-image-group/create-image-group.dialog';
import { UrlService } from '@shared/services/url.service';
import {Alert} from '@shared/models';
import {ConfirmationModal} from '@app/components';

@Component({
	selector: 'app-image-groups',
	templateUrl: './image-groups.component.html',
	styleUrls: ['./image-groups.component.scss']
})
export class ImageGroupsComponent {

	@Input('project') set inProject(project) {
		if (!isNil(project?.id) && project.id !== this.project?.id) {
			this.project = project;
			this.getImageGroups(project);
		}
	}
	@Input() disableMove: boolean = false;
	@Input() hasPermissions;
	@Output('openUpload') openUpload: EventEmitter<any> = new EventEmitter();

	public project;
	public imageGroups;
	public selectedImageGroup;
	public isLoading = true;
	public sortByNewest = true;
	public editName: boolean = false;
	public projectListSubscription: any;

	constructor(
		private _imageService: ImageService,
		private _alertService: AlertService,
		private _dialog: MatDialog,
		private _urlService: UrlService,
		private _cdr: ChangeDetectorRef,
		private _uploadService: UploadService,
	) {
		this.projectListSubscription = this._uploadService.projectListChange.subscribe(({ renderState }) => {
			this.trackAndUpdateUploads(renderState);
		});
	}

	trackAndUpdateUploads(renderState) {

		const project = this.project?.id ? renderState.projectList?.find(byId(this.project.id)) : null;

		if (project?.files?.length) {
			const imageList = project.files.filter(x =>
				!x.trash &&
				x.uploadType === "Image" &&
				x.status !== "SUCCESS"
			);
			if (imageList?.length) {
				const existingBatchIds = this.imageGroups?.map((imageGroup) => {
					return imageGroup.id;
				})
				if (existingBatchIds?.length) {
					const allMatch = imageList.every((image) => {
						return existingBatchIds.includes(image.batchId);
					})
					if (!allMatch) {
						this.getImageGroups(this.project);
					}
				} else {
					this.getImageGroups(this.project);
				}
			}
		}
	}

	getImageGroups(project) {
		this._imageService.getBatchListV2({ project_id: project.id }).then(rtnBatches => {
			this.sortImageGroups(true, rtnBatches);
			this.isLoading = false;
		}).catch(err => {
			console.error(err);
			this.isLoading = false;
			this._alertService.error(new Alert('Failed to load imageGroups, please return to Projects and try again.'));
		})
	}

	updateImageGroup(imageGroup) {
		let updateIndex = this.imageGroups.findIndex(x => x.id === imageGroup.id);
		if (updateIndex > -1) {
			this.imageGroups[updateIndex] = imageGroup;
		} else {
			console.warn('Could not update imageGroup', imageGroup);
		}
	}

	openCreate() {
		const dialogRef = this._dialog.open(CreateImageGroupDialog, { data: { project_id: this.project.id } });

		dialogRef.afterClosed().subscribe((rtnBatch) => {
			if (rtnBatch?.id) {
				this.imageGroups.unshift(rtnBatch);
				this.sortImageGroups(this.sortByNewest);
				this.selectedImageGroup = rtnBatch;
			}
		})
	}

	sortImageGroups(sort?: boolean, inImageGroups?: Array<any>) {

		if (inImageGroups) this.imageGroups = inImageGroups;

		if (this.imageGroups?.length) {
			this._urlService.getQueryParams().then(({ imageGroup }) => {
				const imageGroupId = parseInt(imageGroup);
				if (!isNil(imageGroupId)) {
					const group = this.imageGroups.find(byId(imageGroupId));
					if (group?.id) this.selectedImageGroup = group;
				}
			})

			this.sortByNewest = isNil(sort) ? !this.sortByNewest : sort;
			this.imageGroups = this.imageGroups.sort(this.sortByNewest ?
				sortByNewestToOldest :
				sortByOldestToNewest
			);
		} else {
			this.imageGroups = [];
		}
	}

	openMenu(e, usePosition): void {

		e.preventDefault();
		e.stopPropagation();
	}

	outEvent(event, imageGroup, images?): void {
		if (event.type && event.images) {
			this.outEvent(event.type, imageGroup, event.images)
		}
		if (event === "upload") this.emitUpload({imageGroup});
		if (event === "delete") this.deleteImageGroup(imageGroup);
		if (event === "rename") this.selectAndRename(imageGroup);
	}

	selectAndRename(imageGroup) {
		this.selectedImageGroup = imageGroup;
		this.editName = true;
	}

	deleteImageGroup(imageGroup) {

		const confirmOptions = {
			title: `Trash Image Group`,
			text: `"${imageGroup.name}" and its images will be moved to Trash.`,
			buttonText: `Move to Trash`
		};

		const dialogRef = this._dialog.open(ConfirmationModal, {data: confirmOptions});

		dialogRef.afterClosed().subscribe((confirm) => {
			if (confirm) {
				this._imageService.trashBatchV2(imageGroup).then(() => {
					if (this.selectedImageGroup?.id === imageGroup.id) {
						this.selectedImageGroup = null;
					}
					const batchIndex = this.imageGroups.findIndex(byId(imageGroup.id));
					this.imageGroups.splice(batchIndex, 1);
					this.sortImageGroups(this.sortByNewest);
					this._cdr.detectChanges();
					this._alertService.notify(`Moved Image Group "${imageGroup.name}" to Trash`, "success");
				}).catch(err => {
					console.error(err);
					this._alertService.notify("Could not move Image Group to Trash", "error");
				});
			}
		})
	}

	emitUpload(options) {
		this.openUpload.emit(options);
	}

	selectImageGroup(imageGroup) {
		this.selectedImageGroup = imageGroup;
		this.editName = false;
	}

}
