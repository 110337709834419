/* Imports */
import { Marker, Polyline, CustomMarker } from "@shared/models/annotation.model";
import { store } from "@app/store";
import { api as annotationsAPI, api } from "@features/Annotations";
import { Project } from "@shared/models";
import { ActivatedRoute } from "@angular/router";
import { AnnotationType, MeasurementColor, ModelMeasure, OrthoMeasure } from "@app/pages/viewer/classes/measure";
import { cloneDeep, isEmpty, isNil } from "lodash";

let _project: Project, _modelId: number;

const getAnnotations = ( project = _project ) => {
	return store
		.dispatch( annotationsAPI.endpoints.getList.initiate( project.id ) );
};

const createAnnotation = ( data ): Promise<any> => {
	if ( data.type === AnnotationType.POLYLINE ) { // Temporary translation from old viewer to new, no real way to differentiate
		return createPolyline( data ); // More than 1 point = polyline which is INCORRECT, but temporary
	} else if ( data.type === AnnotationType.MARKER ) {
		return createMarker( data );
	} else if ( data.type === AnnotationType.CUSTOM_MARKER ) {
		return createCustomMarker( data );
	} else return Promise.reject();
};

const createMarker = ( {
						   modelId = _modelId,
						   name = "",
						   type,
						   details = "",
						   points = [] // Probably should be changed to `point`
					   } ) => {
	const annotation = new Marker( {
		name: name,
		details,
		type: AnnotationType.MARKER,
		color: MeasurementColor.DEFAULT,
		model_references: [ modelId ],
		descriptors: {
			point: pointXYZtoLatLngAlt( points[ 0 ] )
		},
		project_id: _project.id
	} );

	return store
		.dispatch( api.endpoints.create.initiate( annotation ) );
};

const createPolyline = ( {
							 modelId = _modelId,
							 name = "",
							 details = "",
							 points = []
						 } ) => {
	const annotation = new Polyline( {
		name: name,
		details,
		type: AnnotationType.POLYLINE,
		color: MeasurementColor.DEFAULT,
		model_references: [ modelId ],
		descriptors: {
			points: pointsXYZtoLatLngAlt( points ),
			connected: true
		},
		project_id: _project.id
	} );

	return store
		.dispatch( api.endpoints.create.initiate( annotation ) );
};

const createCustomMarker = ( {
						   modelId = _modelId,
						   name = "",
						   type,
						   details = "",
						   points = [],
						   fields = {}
					   } ) => {
	const annotation = new CustomMarker( {
		name: name,
		details,
		type: AnnotationType.CUSTOM_MARKER,
		color: MeasurementColor.DEFAULT,
		model_references: [ modelId ],
		descriptors: {
			point: pointXYZtoLatLngAlt( points[ 0 ] ),
			fields
		},
		project_id: _project.id
	} );

	return store
		.dispatch( api.endpoints.create.initiate( annotation ) );
};

const getDescriptors = ( points ) => {
	return ( points.length === 1 ) ? ( {
		point: points[ 0 ]
	} ) : ( {
		connected: true,
		points: pointsXYZtoLatLngAlt( points )
	} );
};

const updateAnnotation = ( { name, details, points, fields, measurementId: annotation_id } ) => {
	const updates = {
		name: name,
		details,
		descriptors: getDescriptors( points )
	};
	
	if(fields) {
		//@ts-ignore
		updates.descriptors.fields = fields;
	}
	
	return store
		.dispatch(
			api.endpoints.update.initiate( {
				annotation_id,
				updates
			} )
		);
};

const deleteAnnotation = ( annotationId ): any => {
	return store
		.dispatch( api.endpoints.delete.initiate( annotationId ) );
};

export const pointsXYZtoLatLngAlt = ( points ) =>
	points.map( point =>
		pointXYZtoLatLngAlt( point )
	).filter( x => !isNil( x ) );

export const pointXYZtoLatLngAlt = ( point ) => {
	if ( isEmpty( point ) ) return {};
	if ( isLatLngAlt( point ) ) return point;
	const { x, y, z } = point;
	return isNil( z ) ? { lat: x, lng: y } : { lat: x, lng: y, alt: z };
};

export const pointsLatLngAlttoXYZ = ( points ) =>
	points.map( point =>
		pointLatLngAlttoXYZ( point )
	).filter( x => {
		return !isNil( x );
	} );

export const pointLatLngAlttoXYZ = ( point ) => {
	if ( isEmpty( point ) ) return {};
	if ( isEmpty( point ) ) return null;
	if ( isXYZ( point ) ) return point;
	const { lat, lng, alt } = point;
	return isNil( alt ) ? { lat, lng } : { x: lat, y: lng, z: alt }; // TEMP returns lat, lng for
};

const isLatLngAlt = ( point ) => ( !isEmpty( point ) && "lat" in point && "lng" in point );
const isXYZ = ( point ) => ( !isEmpty( point ) && "x" in point && "y" in point );

const annotationsToMeasurements = ( annotations = [] ) =>
	cloneDeep( annotations ).reduce(
		( acc, m ) => {
			if ( m.descriptors?.points?.length ) {
				const xyzPoints = pointsLatLngAlttoXYZ( m.descriptors.points );
				if ( xyzPoints.length )
					acc.push( {
						...m,
						data: { points: xyzPoints },
						model_id: parseInt( m.model_references )
					} );
			} else if ( !isEmpty( m.descriptors?.point ) ) {
				const xyzPoint = pointLatLngAlttoXYZ( m.descriptors.point );
				acc.push( {
					...m,
					data: { points: [ xyzPoint ], isMarker: true },
					model_id: parseInt( m.model_references )
				} );
			} else {
				acc.push(m);
			}
			return acc;
		}, [] );

export class Annotations {
	constructor(
		private _route: ActivatedRoute
	) {
		this._route.queryParams.subscribe( ( { model } ) => {
			_modelId = model;
		} );
	}	// End-of constructor

	public get project(): Project {
		return _project;
	}

	public static setProject( project ) {
		_project = project;
	}

	public static setModelId( modelId: number ) {
		_modelId = modelId;
	}

	public static getList( modelId ) {
		return getAnnotations().then( ( { data } ) => {
			return annotationsToMeasurements( data );
		} );
	}

	public static create( data ) {
		return createAnnotation( data ).then(rtn => rtn?.data );
	}

	public static update( data ) {
		return updateAnnotation( data );
	}

	public static delete( {
							  measurementId
						  } ): Promise<void> {
		return deleteAnnotation( measurementId );
	}
}

export class Mark2D extends OrthoMeasure {
	constructor( viewer, params: any = {} ) {
		super( viewer, params );
		this.maxMarkers = 1;
	}
}

export class Mark3D extends ModelMeasure {
	constructor( viewer, params: any = {} ) {
		super( viewer, params );
		this.maxMarkers = 1;
	}
}


export class CustomMark2D extends OrthoMeasure {
	public fields: any;
	constructor( viewer, f, params: any = {} ) {
		super( viewer, params );
		this.maxMarkers = 1;
		this.fields = f;
	}
}
