/* Imports */
import {Injectable, NgZone} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {GlobalService} from './global.service';
import {SessionService} from './session.service';
import {UtilsService} from './utils.service';
import {Buffer} from 'buffer';
import {BehaviorSubject} from 'rxjs';
import _ from "lodash";

/* Models */
import {User, Alert} from '../models';

/* Services */
import {HttpService} from './http.service';
import {AlertService} from './alert.service';

/* Providers */
import {LoginProvider} from '../providers';

/* Internal */
import {CustomState} from '../classes/customState';

export class AuthenticationServiceConfiguration {
	/* Variables */
	public providers: Map<string, LoginProvider> = new Map<string, LoginProvider>();

	constructor(providers: Array<any>) {
		if (providers) {
			for (let i = 0; i < providers.length; i++) {
				let element = providers[i];
				this.providers.set(element.id, element.provider);
			}
		}
	}    // End-of constructor
}	// End-of class AuthenticationServiceConfiguration

@Injectable()
export class AuthenticationService {

	private _user: CustomState<User> = new CustomState(new User());
	private _activeProviderId: string;	// The provider used to login
	private _providers: Map<string, LoginProvider>;	// Map of all the providers we support
	private _providerUser: CustomState<any> = new CustomState(null);
	private _queryParams: any;
	private _orgStatusCheck: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public orgObservable = this._orgStatusCheck.asObservable();

	constructor(
		private _config: AuthenticationServiceConfiguration,
		private _alertService: AlertService,
		private _http: HttpClient,
		private _route: ActivatedRoute,
		private _router: Router,
		private httpService: HttpService,
		private _ngZone: NgZone
	) {
		this._route.queryParams.subscribe(params => {
			this._queryParams = params;
		});

		this._router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				(/^\/forgot|\/login/).test(event.url) && this.logout();
			}
		})

		// Initialize the providers
		this._providers = _config.providers;
		this._providers.forEach((provider: LoginProvider, key: string) => {
			provider.initialize(this)
				.then((user: User) => {
					if (user && user.google_token) {
						this._providerUser.data = user;
						this.loginByToken(key, user.google_token);
					}
				})
				.catch((error: any) => {
					console.error("Provider Error - ", error);
				});
		});

		this._user.state.subscribe(data => {
			if (data) {
				if (SessionService.isActive && data.api_key != this._user.data["token"] || !SessionService.isActive) {
					SessionService.startSession(data.api_key);
					SessionService.set("user", data);
					setTimeout(() => {
						this.refetchLoop()
					}, 100);
				}
			}
		});

		if (SessionService.isActive) {
			this._user.data = SessionService.get("user");
			this.refetchLoop();
		}

		// Add to global service
		GlobalService.authenticationService = this;

		this.checkLogout();
	}    // End-of constructor

	public checkLogout(): void {
		var check = setInterval(() => {
			if (this._user && this._user.data && this._user.data.api_key && !SessionService.isActive) {
				this._user.data = null;
				this._providerUser.data = null;
				window.location.reload();
				clearInterval(check);
			}
		}, 3000);
	}

	public get token(): any {
		// return this._user.data.google_token;
		return this._user.data.api_key;
	}	// End-of token

	public get userState(): CustomState<User> {
		return this._user;
	}	// End-of userState

	public get user(): any {
		return this._user.data;
	}	// End-of user

	get isDeveloper(): boolean {
		let user = this._user.data;
		if (!user || (user == undefined) || (user.email == undefined)) {
			return false;
		}

		let isDeveloper = UtilsService.emailDomainCheck(this._user.data.email,
			'aerialapplications.com',
			'aerialapplications.co',
			'mapware.io',
			'mapware.com',
			'mapware.ai');

		// Toggle developer via queryParams
		if (isDeveloper && this._queryParams.isDeveloper != undefined) {
			isDeveloper = !!Number(this._queryParams.isDeveloper);
		}
		return isDeveloper;
	}	// End-of isDeveloper

	public getProvider(providerId: string): LoginProvider {
		return this._providers.get(providerId);
	}	// End-of provider

	public loginByApiKey(apiKey: any): Promise<any> {
		return new Promise((resolve: Function, reject: Function) => {

			let url = GlobalService.databaseApiUrl + "/user";

			let headers = new HttpHeaders().set('x-api-key', apiKey);

			this._http.get<any>(url, {headers}).subscribe(rtn => {
				this._user.data = rtn;
				this.refetchLoop();
				resolve(this._user.data);
			}, error => {
				reject(error);
			});
		});
	}	// End-of loginByApiKey

	public loginByEmail(email: string, password: string): Promise<any> {

		return new Promise((resolve: Function, reject: Function) => {

			let url = GlobalService.databaseApiUrl + "/user/login"

			let headers = new HttpHeaders().set('Content-Type', 'application/json');

			const auth = "Basic " + new Buffer(email + ":" + password).toString("base64");

			headers = headers.append('Authorization', auth);

			this._http.post<any>(url, {}, {headers}).subscribe(rtn => {
				this._user.data = rtn;
				this.refetchLoop();
				resolve(this._user.data);
			}, error => {
				reject(error);
			});
		});

	}	// End-of login

	public loginByToken(providerId: string, token: string): Promise<any> {

		return new Promise((resolve: Function, reject: Function) => {
			const url = GlobalService.databaseApiUrl + "/user/login";
			let headers = new HttpHeaders().set('Content-Type', 'application/json');
			let body = {google_token: token};

			this._http.post<any>(url, body, {headers}).subscribe(rtn => {
				this._activeProviderId = providerId;

				if (this._providerUser.data) {
					let proUser = this._providerUser.data;
					rtn.first_name = (rtn.first_name ? rtn.first_name : proUser.first_name);
					rtn.last_name = (rtn.last_name ? rtn.last_name : proUser.last_name);
				}

				SessionService.set("recentLogin", true); // Ensure any notification won't pop up on login

				this._user.data = rtn;
				this.refetchLoop();
				resolve(this._user.data);
			}, error => {
				this._activeProviderId = providerId;
				this.logout().then(() => {
					// if(!this._router.url.includes('/login')){
					//only redirect if NOT on the login page... otherwise this can trap user that starts sign up with google and then changes their mind
					//see GitLab https://gitlab.aerialapplications.com/front-end/mapware/-/issues/310
					this._ngZone.run(() => {
						this._alertService.error(new Alert("Your Google Account isn't associated with a Mapware account. Please register your account here first."))
						this._router.navigate(['register']); // .then(() => location.reload()); // commenting this reload out, to see if its still needed - Nic L
					});
					// }
					reject(error);
				});
			});
		});

	}	// End-of loginByToken

	public logout(provider?): Promise<any> {

		return new Promise((resolve: Function, reject: Function) => {

			// Logout of provider session
			if (this._activeProviderId || provider) {
				if (!provider) {
					provider = this.getProvider(this._activeProviderId);
				}
				if (provider) {
					provider.signout().then(() => {
						// Logout of local user session
						if (this._user) {
							this._user.data = null;
							SessionService.clear();
						}
						resolve();
					});
				}
			} else {
				// Logout of local user session
				if (this._user) {
					this._user.data = null;
					this._providerUser.data = null;
					SessionService.clear();
				}
				resolve();
			}

		});

	}	// End-of logout

	public recoverPassword(email: string): Promise<any> {

		return new Promise((resolve: Function, reject: Function) => {
			const url = GlobalService.databaseApiUrl + "/users/reset_password";
			let headers = new HttpHeaders().set('Content-Type', 'application/json');

			let body = {email: email};

			this._http.post<any>(url, body, {headers, responseType: 'text' as 'json'}).subscribe(rtn => {
				resolve(rtn);
			}, error => {
				reject(error);
			});
		});

	}	// End-of recoverPassword

	public resetPassword(password: string, token?: string): Promise<any> {

		return new Promise((resolve: Function, reject: Function) => {
			let url = GlobalService.databaseApiUrl;
			let headers = new HttpHeaders().set('Content-Type', 'application/json');

			let body = {password: password};
			if (this.user && this.user.api_key) {
				url += "/user/update_password";
			} else {
				url += "/user/set_password";
				body['token'] = token || this.user.api_key;
			}

			this._http.post<any>(url, body, {headers}).subscribe(rtn => {
				resolve(rtn);
			}, error => {
				reject(error);
			});
		});

	}	// End-of resetPassword


	public refetch(): void {

		this.getUser().then(rtnUser => {
			if (rtnUser?.id) this.updateUser(rtnUser);
			setTimeout(() => {
				this.refetchLoop();
			}, 30000);
		}).catch(err => {
			console.error(err);
		})

	}	// End-of refetch

	getUser = () =>
		this.httpService.get('/user')
			.catch(err => {
				//console.error(err)
			}); // Not sure why, but won't loop without this - Luke

	updateUser = (user) => {
		const last_updated = new Date(this.user.updated_at);
		const new_updated = new Date(user.updated_at);

		if (new_updated.getTime() !== last_updated.getTime()) {
			this._orgStatusCheck.next(true);
			this._user.data = user;
		}
	}

	public verify(verification_token: any): Promise<any> {

		let url = '/user/verify_email';
		return this.httpService.post(url, {email_code: verification_token});

	}	// End-of verify

	public resendVerification(email: string): Promise<any> {

		let url = '/user/resend_verification_email';
		return this.httpService.post(url, {email: email});

	}	// End-of resendVerification

	private refetchLoop(): void {

		const user = this.user;
		if (user && !_.isNil(user.id)) {
			this.refetch();
		}

	}	// End-of refetchLoop

}	// End-of class AuthenticationService
