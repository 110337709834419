/* Imports */
import { NgModule } from '@angular/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CommonModule } from "@angular/common";
import { RouterModule } from '@angular/router';

// File imports
import { DragDropModule } from "@angular/cdk/drag-drop";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TrialComponent } from "@app/components/trial";
import { ImageGroupsComponent } from "@app/components/image-groups";
import { ImageGroupLabelComponent } from "@app/components/image-groups/label/image-group-label.component";
import { ImagesComponent } from "@app/components/image-groups/images";
import { CreateImageGroupDialog } from "@app/components/image-groups/create-image-group";
import { ImageComponent } from "@app/components/image-groups/image";
import { ImageGroupComponent } from "@app/components/image-groups/image-group";
import { NewSectionComponent, SectionComponent, SectionsComponent } from "@app/components/sections";
import { PhotoTagComponent } from "@app/components/photo-tag";
import { AdminViewComponent } from "@app/components/admin-view";
import { ContactComponent } from "@app/components/contact";
import { CoordinateSystemComponent } from "@app/components/coordinate-system";
import { FeatureFlagDirective } from "@app/shared/directives/featureflag/featureflag.directive";
import { AerialAvatar } from "@app/components/avatar";
import { AerialSidenav, AerialSidenavListItem } from "@app/components/sidenav";
import { ScrollLoadComponent } from "@app/components/scroll-load";
import { UploadCircleComponent } from "@app/components/upload-circle";
import { PaywallComponent } from "@app/components/paywall/paywall.component";
import { MapwareFlyDialog } from "@app/components/mapware-fly/mapware-fly.dialog";

/* Material */
import { MaterialModule } from "@shared/modules/material.module";

import { ChargebeeJsAngularWrapperModule } from "@chargebee/chargebee-js-angular-wrapper";
import { BytesPipe } from "@shared/pipes/bytes.pipe";
import { CustomDatePipe } from "@shared/pipes/customDatePipe.pipe";
import { PhonePipe } from "@shared/pipes/phone.pipe";
import { CommaPipe } from "@shared/pipes/comma.pipe";
import { PluralPipe } from "@shared/pipes/plural.pipe";
import { RegisterCycleComponent } from "@app/pages/register";
import { TeamSelectComponent } from "@app/components/team-select/team-select.component";
import { PaginatorComponent } from "@app/components/paginator/paginator.component";
import { AerialListGridItem } from "@app/components/projects/list-grid-item";
import { AerialListImageItem } from "@app/components/projects/list-image-item";
import { MapPreviewComponent } from "@app/components/map-preview/map-preview.component";
import { TagsComponent } from "@app/components/tags/tags.component";
import { CreateGCPModal } from "@app/components/gcp/create/create-gcp.modal";
import { FileHandlerModal } from "@app/components/file-handler/file-handler.modal";
import { ViewGCPComponent } from "@app/components/gcp/view-gcp.component";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { VirtualScrollerModule } from "@iharbeck/ngx-virtual-scroller";
import {AerialDropzone} from "@app/components";
import { PortalHookComponent } from "@app/components/portal-hook/portal-hook.component";
import { NgxDropzoneModule } from "ngx-dropzone";
import { InfoBoxComponent } from "@app/components/info-box";
import { NgCircleProgressModule } from "ng-circle-progress";
import { ExportDialog } from "@app/components/export-dialog/export.dialog";
import { ImportDialog } from "@app/components/import-dialog/import.dialog";
import { MarkerImportDialog } from "@app/components/marker-import-dialog/marker-import.dialog";
import { UploadDialog } from "@app/components/upload-dialog/upload.dialog";
import { NewProcessComponent } from "@app/components/process/process.component";
import { AnalysisComponent } from "@app/pages/ai/analysis.component";
import { MinimapComponent } from "@app/components/minimap/minimap.component";
import { PhotoComponent } from "@app/pages/projects/view/photos/photo.component";
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
	declarations: [
		// Pipes
		BytesPipe,
		CustomDatePipe,
		PhonePipe,
		CommaPipe,
		PluralPipe,
		// Components
		AerialAvatar,
		AerialListGridItem,
		AerialListImageItem,
		TagsComponent,
		AerialSidenav,
		AerialSidenavListItem,
		PaywallComponent,
		MapwareFlyDialog,
		AdminViewComponent,
		CreateGCPModal,
		ViewGCPComponent,
		ContactComponent,
		CoordinateSystemComponent,
		FileHandlerModal,
		MapPreviewComponent,
		NewSectionComponent,
		PhotoTagComponent,
		RegisterCycleComponent,
		SectionComponent,
		SectionsComponent,
		ScrollLoadComponent,
		TrialComponent,
		InfoBoxComponent,
		TeamSelectComponent,
		PaginatorComponent,
		UploadCircleComponent,
		// Directives
		FeatureFlagDirective,
		AerialDropzone,
		ExportDialog,
		ImportDialog,
		MarkerImportDialog,
		UploadDialog,
		PortalHookComponent,
		MinimapComponent,
		ImageGroupComponent,
		ImageGroupsComponent,
		ImagesComponent,
		ImageComponent,
		ImageGroupLabelComponent,
		CreateImageGroupDialog,
		PhotoComponent,
		NewProcessComponent,
		AnalysisComponent
	],
	imports: [
		CommonModule,
		MaterialModule,
		LazyLoadImageModule,
		LeafletModule,
		FormsModule,
		NgxDropzoneModule,
		ReactiveFormsModule,
		DragDropModule,
		ChargebeeJsAngularWrapperModule,
		RouterModule,
		NgCircleProgressModule.forRoot(),
		VirtualScrollerModule,
		ColorPickerModule
	],
	exports: [
		// Pipes
		BytesPipe,
		CustomDatePipe,
		PhonePipe,
		CommaPipe,
		PluralPipe,
		// Components
		AerialAvatar,
		AerialListGridItem,
		AerialListImageItem,
		TagsComponent,
		AerialSidenav,
		AerialSidenavListItem,
		PaywallComponent,
		MapwareFlyDialog,
		AdminViewComponent,
		CreateGCPModal,
		ViewGCPComponent,
		ContactComponent,
		CoordinateSystemComponent,
		FileHandlerModal,
		MapPreviewComponent,
		NewSectionComponent,
		PhotoTagComponent,
		RegisterCycleComponent,
		SectionComponent,
		SectionsComponent,
		ScrollLoadComponent,
		TrialComponent,
		InfoBoxComponent,
		TeamSelectComponent,
		PaginatorComponent,
		UploadCircleComponent,
		// Directives
		FeatureFlagDirective,
		// Exports
		LeafletModule,
		ChargebeeJsAngularWrapperModule,
		AerialDropzone,
		ExportDialog,
		ImportDialog,
		MarkerImportDialog,
		UploadDialog,
		PortalHookComponent,
		MinimapComponent,
		ImageGroupComponent,
		ImageGroupsComponent,
		ImagesComponent,
		ImageComponent,
		ImageGroupLabelComponent,
		CreateImageGroupDialog,
		PhotoComponent,
		NewProcessComponent,
		VirtualScrollerModule,
		ColorPickerModule
	]
})
export class SharedModule {
}
