
<ng-container *ngIf="isLoading">
	<ng-container *ngTemplateOutlet="t_loading"></ng-container>
</ng-container>
<ng-container *ngIf="!isLoading">
	<ng-container *ngTemplateOutlet="t_content"></ng-container>
</ng-container>

<ng-container *featureEnabled="'temp-project-view-export-layers'">
	<ng-container *ngTemplateOutlet="t_modelDetails"></ng-container>
</ng-container>

<ng-template #t_loading>
	<div class="row">
		<div class="row-toggle view-skel-item view-skel-shimmer"></div>
		<div class="row-thumbnail view-skel-item view-skel-shimmer"></div>
		<div class="row-name view-skel-item view-skel-shimmer"></div>
		<div class="row-details view-skel-item view-skel-shimmer"></div>
		<div class="row-details"></div>
		<div class="row-toggle view-skel-item view-skel-shimmer"></div>
	</div>
</ng-template>

<ng-template #t_content>
	<div class="row"
		 [ngClass]="{'openable': (!model.isLayer && modelDataSource?.data?.length)}"
		 (click)="toggleExpand($event);"
		 (contextmenu)="openMenu($event)">
		<span class="row-toggle" *featureEnabled="'temp-project-view-export-layers'">
			<mat-icon *ngIf="!model.isLayer && modelDataSource?.data?.length" inline="true" class="arrow-indicator" [class.arrow-indicate-expanded]="expanded">
				arrow_forward_ios
			</mat-icon>
		</span>

		<span class="row-name">
			<span class="row-thumbnail">
				<img *ngIf="model.thumbnail" src="{{model.thumbnail}}" alt="model" class="thumbnail">
				<ng-container *ngIf="!model.thumbnail">
					<ng-container *ngTemplateOutlet="t_thumbnail"></ng-container>
				</ng-container>
			</span>
			<span
				(click)="openModelViewer(model.id); $event.stopPropagation();"
				matTooltip="Open Model"
				[ngClass]="{'disabled': !isModelEnabled(model)}"
			>
				{{model.name ? model.name : 'Untitled'}}
			</span>
		</span>
		<span class="row-model-id">
			ID: {{model.id}}
		</span>
		<span class="row-details">
			{{getModelInfoString(model)}}
			<span [ngClass]="{'error-status': hasFailedLayer(model.layers)}" *ngIf="modelDataSource?.data?.length">
				- {{getModelStatus(model)}} on {{ model.created_at | date }}
			</span>
		</span>

		<span *ngIf="model.statusExplanation"
			  matTooltipPosition="after"
			  class="info-tip status-tip phone-hidden"
			  (click)="$event.stopPropagation();"
			  [matTooltip]="model.statusExplanation">
			<mat-icon>help_outline</mat-icon>
		</span>

		<span>
			<mat-icon class="open-map-icon" [ngClass]="{'disabled': !isModelEnabled(model)}" (click)="openModelViewer(model.id); $event.stopPropagation();"> map</mat-icon>
		</span>

		<span class="row-menu">
			<ng-container *ngTemplateOutlet="t_modelMenu"></ng-container>
		</span>
	</div>
</ng-template>

<ng-template #t_thumbnail>
	<div *ngIf="getDataIcon(model)"
		 class="thumbnail-icon"
		 [ngClass]="{'disabled': !isModelEnabled(model), 'error-status': hasFailedLayer(model.layers)}">
		<mat-icon class="data-icon" [ngClass]="{'error-status': hasFailedLayer(model.layers)}">{{ getDataIcon(model) }}</mat-icon>
		<ng-container *ngIf="!hasFailedLayer(model.layers)">
			<mat-icon *ngIf="getFileIcon(modelType)" class="file-icon">{{ getFileIcon(modelType) }}</mat-icon>
			<span *ngIf="modelType">{{ modelType }}</span>
		</ng-container>
	</div>
	<div *ngIf="!getDataIcon(model)" class="default-thumbnail-div">
		<img src="/assets/icons/aa_logo.png" alt="mapware logo" class="default-thumbnail">
	</div>
</ng-template>

<ng-template #t_modelMenu>
	<ng-container *ngIf="!isLoading">
		<button
			class="button-enabled"
			mat-icon-button tooltip="More"
			(click)="openMenu($event); $event.stopPropagation();">
			<mat-icon class="list-item-icon">more_horiz</mat-icon>
		</button>
	</ng-container>
	<ng-container *ngIf="isLoading">
		<div class="view-skel-item view-skel-shimmer" style="height: 20px; width: 20px; margin: 8px"></div>
	</ng-container>

	<button
		class="context-button"
		[matMenuTriggerFor]="itemMenu"
		[style.left]="contextMenuPosition.x"
		[style.top]="contextMenuPosition.y">
	</button>

	<mat-menu #itemMenu="matMenu" class="little-menu">
		<ng-template matMenuContent>
			<mat-list style="width: 100%; padding: 2px 0;" class="centered-menu-text">

				<mat-list-item *ngIf="isModelEnabled(model)" (click)="openModelViewer(model.id);">
					<mat-icon> layers </mat-icon>
					<span> Open </span>
				</mat-list-item>

				<mat-list-item [ngClass]="{'disabled-item': !getPermission()}"(click)="renameModel(model);">
					<mat-icon> edit </mat-icon>
					<span> Rename </span>
				</mat-list-item>

				<ng-container *ngIf="isModelEnabled(model)">
					<mat-list-item (click)="openShareLinkDialog(model);">
						<mat-icon> share </mat-icon>
						<span> Share Model </span>
					</mat-list-item>

					<mat-list-item [ngClass]="{'disabled-item': isModelImported(model)}" *featureEnabled="'!disable-exports'" (click)="openExportDialog(model);">
						<mat-icon> merge_type </mat-icon>
						<span> Export Model </span>
					</mat-list-item>
				</ng-container>

				<mat-list-item [ngClass]="{'disabled-item': !getPermission()}" (click)="trashModel(model);">
					<mat-icon> delete_outline </mat-icon>
					<span> Trash </span>
				</mat-list-item>
			</mat-list>
		</ng-template>
	</mat-menu>
</ng-template>

<ng-template #t_modelDetails>
	<div *ngIf="modelDataSource?.data?.length" class="content" [ngClass]="{ 'expanded': expanded }">
		<table mat-table [dataSource]="modelDataSource" class="layers-table" class="layer-row">

			<ng-container matColumnDef="name">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell
					data-testid="name-cell"
					*matCellDef="let row"
					class="name-column"
				>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="data-type">
				<mat-header-cell *matHeaderCellDef class="mat-header">Name</mat-header-cell>
				<mat-cell *matCellDef="let row">
					<ng-container *ngIf="isLoading">
						<div class="row-name view-skel-item view-skel-shimmer"></div>
					</ng-container>
					<ng-container *ngIf="!isLoading">
						<div class="layer-icon">
							<mat-icon>{{getDataLabel(row.dataTypeLabel)}}</mat-icon>
						</div>
						<span
							*ngIf="renamePointCloudLayers(row)"
							[ngClass]="{ 'layers-name' : row.status === 'Rendered' }"
						    [matTooltip]="row.status === 'Rendered' ? 'Open Layer' : null"
						    (click)="row.status === 'Rendered' && openModelLayer(row); $event.stopPropagation();">
							{{ row.name }}
						</span>
					</ng-container>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="layer-id">
				<mat-header-cell *matHeaderCellDef class="mat-header">ID</mat-header-cell>
				<mat-cell *matCellDef="let row">
					<ng-container *ngIf="isLoading">
						<div class="row-name view-skel-item view-skel-shimmer"></div>
					</ng-container>
					<ng-container *ngIf="!isLoading">
						<span>
							{{ row.id }}
						</span>
					</ng-container>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="status">
				<mat-header-cell *matHeaderCellDef class="mat-header">Status</mat-header-cell>
				<mat-cell *matCellDef="let row">
					<ng-container *ngIf="isLoading">
						<div class="row-name view-skel-item view-skel-shimmer"></div>
					</ng-container>
					<ng-container *ngIf="!isLoading">
							<span class="ellipsis">
								{{ row.status ? row.status : "Created" }}
							</span>
					<span
						*ngIf="row.statusExplanation"
						matTooltipPosition="after"
						class="info-tip status-tip phone-hidden"
						(click)="$event.stopPropagation()"
						[matTooltip]="row.statusExplanation"
					>
								<mat-icon>help_outline</mat-icon>
							</span>
					</ng-container>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="created_at">
				<mat-header-cell *matHeaderCellDef class="mat-header">Processed On</mat-header-cell>
				<mat-cell
					class="phone-hidden"
					data-testid="created_at-cell"
					*matCellDef="let row"
				>
					<ng-container *ngIf="isLoading">
						<div class="row-name view-skel-item view-skel-shimmer"></div>
					</ng-container>
					<ng-container *ngIf="!isLoading">
						{{ row.created_at ? (row.created_at | date) : "- -" }}
					</ng-container>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="more-options">
				<mat-header-cell *matHeaderCellDef class="mat-header"></mat-header-cell>
				<mat-cell *matCellDef="let row">
					<ng-container *ngIf="!isLoading">
						<button *ngIf="checkDataTypeLabel(row)" class="button-enabled" mat-icon-button tooltip="More" matTooltip="More Options" [matMenuTriggerFor]="layerMenu">
							<mat-icon class="list-item-icon">more_horiz</mat-icon>
						</button>
						<mat-menu #layerMenu="matMenu" class="little-menu layer-menu">
								<mat-list>
									<mat-list-item [ngClass]="{'disabled-item': !getPermission()}" (click)="renameLayer(row);">
										<mat-icon> edit </mat-icon>
										<span> Rename </span>
									</mat-list-item>
								</mat-list>
						</mat-menu>
					</ng-container>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="options">
				<mat-cell
					*matCellDef="let row;"
					style="max-width: 80px"
				>
				</mat-cell>
			</ng-container>
			<mat-header-row *matHeaderRowDef="displayedColumns" class="inner-table-header"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns" class="inner-table-row"></mat-row>
		</table>
	</div>
</ng-template>
