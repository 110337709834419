import { Subscription } from './subscription.model'
export interface _Organization {
	// no change
	id: number;
	// id of the admin user, `org.created_by_id`
	user_id: number;
	// Organization name, Unused
	name: string;
	address: string;
	address_2: string;
	address_3: string;
	city: string;
	state: string;
	zip: string;
	country: string;
	phone: string;
	logo_url: string;
	descriptors: string | null;
	description: string | null;
	subscribed:  1 | 0 ;
	// will only appear for the admin user, indicated by `user_id`
	chargebee_id?: string;
	// number of credits avaialable, no change
	processing_credits: number;
	subscription_credits: number;
	// total storage available in gb `org.additional_storage`
	storage: number;
	// amount of storage used in gb
	size: number;
	// ISO date string eg: 2020-11-18T19:55:20.960Z
	created_at: string;
	// ISO date string eg: 2020-11-18T19:55:20.960Z
	updated_at: string;
	active:  1 | 0 ;
	subscription: Subscription;
	allows_negative_credits: boolean;
}

// Organization Model
export class Organization {

	public id:		number;
	public name:	string;
	public processing_credits: number;
	public subscription_credits: number;
	public storage: number;
	public used_storage: number;
	public remaining_storage: number;
	public orgPlan: any;
	public size: number;
	public subscription: Subscription;
	public allows_negative_credits: boolean;
	public subscribed: boolean;
	public users: Array<any>;
	public isFromProjectShare: boolean;
	public stripe_id?: number;

	constructor(params: any = {}) {
		Object.assign(this, params);
	}	// End-of constructor

}	// End-of class OrganizationModel

export enum USERROLES {
	admin = "admin",
	process = "process",
	user = "user",
	reader = "reader"
}

export interface OrgIdsChangesModel {
	addedOrgIds: Array<number>;
	removedOrgIds: Array<number>;
}
