
<div class="image-group-item"
	*ngIf="imageGroup?.id"
	[ngClass]="{'active': active, 'menu-open': t?.menuOpen}">
	<span class="image-group-info">
		<p class="image-group-name">
			{{ imageGroup.name ?? "Unlabeled Image Group" }}
		</p>
		<span>
			{{ imageGroup.images_count ?? 0 }}
			{{pluralize('Image', (imageGroup.images_count ?? 0))}}
		</span>
	</span>
	<mat-icon class="multi"
			  *ngIf="imageGroup.sensor"
			  matTooltip="Multispectral Image Group"
			  [matTooltipDisabled]="!imageGroup.sensor">
		burst_mode
	</mat-icon>
	<button
			[matMenuTriggerFor]="itemMenu"
			#t="matMenuTrigger"
			(click)="$event.stopPropagation();"
			mat-icon-button>
		<mat-icon>more_horiz</mat-icon>
	</button>
</div>

<mat-menu #itemMenu="matMenu">
	<ng-template matMenuContent>
		<mat-list>
			<mat-list-item (click)="outEvent('rename');" [ngClass]="{'disabled': !hasPermissions}">
				<mat-icon> edit </mat-icon>
				<span> Rename </span>
			</mat-list-item>
			<mat-list-item (click)="outEvent('delete');" [ngClass]="{'disabled': !hasPermissions}">
				<mat-icon> delete_outline </mat-icon>
				<span> Trash </span>
			</mat-list-item>
		</mat-list>
	</ng-template>
</mat-menu>
