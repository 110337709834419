
<ng-container *ngIf="isMobile">
	<ng-container *ngTemplateOutlet="t_mobileHeader"></ng-container>
</ng-container>

<div class="sidenav-parent">
	<div class="sidenav" [ngClass]="{'sidenav-shrunk': !expandSidenav}">
		<div class="sidenav-container">
			<ng-container *ngIf="!isMobile">
				<ng-container *ngTemplateOutlet="t_desktopHeader"></ng-container>
			</ng-container>
			<div class="sidenav-grid">
				<div class="sidenav-flexbox" data-testid="sidenav-paywall">

					<app-paywall [disabled]="disabled">
						<ng-container *ngFor="let item of navItems">
							<sidenav-list-item
									id="{{'sidenav_'+item.name}}"
									matTooltip="{{item.tooltip}}"
									[matTooltipDisabled]="expandSidenav"
									matTooltipPosition="right" class="list-item"
									[ngClass]="{'selected': item.active}"
									[item]="item" [expandSidenav]="expandSidenav"
									(click)="isMobile && toggleSidenav()">
							</sidenav-list-item>
						</ng-container>
					</app-paywall>

					<mat-divider></mat-divider>

					<sidenav-list-item
							*ngIf="!isMobile"
							class="list-item"
							[item]="expandSidenavItem(expandSidenav)"
							[expandSidenav]="expandSidenav"
							matTooltip="Expand"
							[matTooltipDisabled]="expandSidenav"
							matTooltipPosition="right"
							(click)="toggleSidenav();">
					</sidenav-list-item>
				</div>

				<div class="sidenav-flexbox-bottom" data-testid="sidenav-paywall">
					<div
							*featureEnabled="'mapware-fly-promo'"
							class="sidenav-secondary-button"
							matTooltip="Open Mapware Fly in a new Tab"
							[ngClass]="{'expanded': expandSidenav}"
							[matTooltipDisabled]="expandSidenav"
							matTooltipPosition="right"
							(click)="openMapwareFly()">
						<img src="assets/images/Mapware%20Fly-Icon_White_White.svg" alt="Mapware Fly">
					<span *ngIf="expandSidenav" class="ellipsis H6WhiteSemibold"> Try Mapware Fly</span>
					</div>

					<sidenav-list-item
							*ngIf="supportLink"
							class="list-item"
							[item]="supportSidenavItem"
							matTooltip="Help & Feedback"
							[matTooltipDisabled]="expandSidenav"
							matTooltipPosition="right"
							[matMenuTriggerFor]="helpMenu"
							[expandSidenav]="expandSidenav">
					</sidenav-list-item>
				</div>
			</div>

			<mat-menu #helpMenu="matMenu" class="unbounded-mat-mdc-menu-panel">
				<mat-list>
					<mat-list-item *ngIf="supportGuide" (click)="openFAQ()">
						<mat-icon>help</mat-icon>
						<span class="H6Black700Regular">
								Mapware Knowledge Base
							</span>
					</mat-list-item>
					<mat-list-item (click)="sendFeedback()">
						<mat-icon>email</mat-icon>
						<span class="H6Black700Regular">
								Send bug or feedback:
								<b>{{supportLink}}</b>
							</span>
					</mat-list-item>
				</mat-list>
			</mat-menu>
		</div>
	</div>

	<span class="sidenav-content" [ngClass]="{'sidenav-content-shrunk': !expandSidenav}">
		<ng-content></ng-content>
	</span>
</div>

<ng-template #t_mobileHeader>
	<div class="sidenav-top-bar">
		<mat-icon (click)="toggleSidenav();">
			menu
		</mat-icon>
		<span class="aa-logo-container">
			<img class="aa-logo" src="assets/icons/aa_logo.png" routerLink="/">
		</span>
	</div>
</ng-template>

<ng-template #t_desktopHeader>
	<ng-container *ngIf="primaryItem">
		<div id="primary-item"
			 [matTooltip]="primaryItem.tooltip"
			 [matTooltipDisabled]="expandSidenav"
			 matTooltipPosition="right">
			<button [ngClass]="{'disabled grayscale': !primaryItem.active}" mat-flat-button color="primary" (click)="primaryButtonClick()" class="sidenav-button phone-hidden">
				<mat-icon class="mat-icon">
					{{primaryItem.icon}}
				</mat-icon>

				<span *ngIf="expandSidenav" class="primary-text"
					  id="primary-main-card-button">
					{{primaryItem.name}}
				</span>
			</button>
		</div>
	</ng-container>
</ng-template>
