/* Imports */
import _, {isEqual, isNil} from 'lodash';
import { v4 as uuidv4 } from "uuid";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import * as asyncjs from "async";
import { Router, NavigationEnd } from "@angular/router";

/* Models */
import {Upload, Project, Model, ImageBatch} from '../models';

import debounce from "lodash/debounce";

/* Services */
import { AlertService } from "./alert.service";
import { AuthenticationService } from "./authentication.service";
import { ImageService } from "./image.service";
import { OrganizationService } from "./organization.service";
import {FileData, ImportFile, ModelService} from "./model.service";
import { ProjectService } from "./project.service";
import { flagLayer, availableFeatureFlags } from '../featureFlags';


const acceptedImageTypes = [".png", ".jpg", ".jpeg", ".tif", ".tiff"];
export const bailCodes = [400, 401, 403, 404, 412, 502];
import retry from 'async-retry';

const enum statusOptions {
	queued = "QUEUED",
	active = "ACTIVE",
	paused = "PAUSED",
	canceled = "CANCELED",
	failure = "FAILURE",
	success = "SUCCESS"
}

// Function for pausing async functions. TODO: Move into separate service
const waitFor = async (condFunc: () => boolean) => {
	return new Promise<void>((resolve) => {
		if (condFunc()) {
			resolve();
		} else {
			setTimeout(async () => {
				await waitFor(condFunc);
				resolve();
			}, 400);
		}
	});
};
import {bitsToGigabytes, byId, extractFileExtension, getFileExtension} from './utils.service';
import { HttpEventType } from "@angular/common/http";
import { string } from "yargs";
import {first, last, tap, withLatestFrom} from 'rxjs/operators';

const trackedFileTemplate = {
	type: "", // "FILE" | "IMAGE",
	id: -1,
	batchId: null,
	name: "",
	status: statusOptions.queued, // "QUEUED" | "ACTIVE" | "PAUSED" | "CANCELED" | "FAILURE" | "SUCCESS",
	size: 0,
	sizeComplete: 0,
	percentageComplete: null,
	uploadType: ""
};

const addNewUploads = (trackingProjects, projectId, batchId, fileArray) =>
	_.merge({}, trackingProjects, {
		projects: {
			[projectId]: {
				metaData: {},
				status: statusOptions.active,
				// @ts-ignore
				files: Object.fromEntries(
					fileArray.map(({ id, name, type, file, uploadType }) => [
						id,
						{
							file,
							...trackedFileTemplate,
							// @ts-ignore
							id,
							name,
							type,
							batchId,
							uploadType
						},
					])
				),
			},
		},
	});

const addProjectMetaData = (trackingProjects, project) => {
	return _.merge( {}, trackingProjects, {
		projects: {
			[project.id]: {
				metaData: project
			},
		},
	} );
}
const setProjectProp = (trackingProjects, projectId, propObj) =>
	_.merge({}, trackingProjects, {
		projects: {
			// @ts-ignore
			[projectId]: propObj,
		},
	});

const markProjectPaused = (trackingProjects, projectId) =>
	setProjectProp(trackingProjects, projectId, { status: statusOptions.paused });

const markProjectActive = (trackingProjects, projectId) =>
	setProjectProp(trackingProjects, projectId, { status: statusOptions.active });

const markProjectCanceled = (trackingProjects, projectId) =>
	setProjectProp(trackingProjects, projectId, { status: statusOptions.canceled, finished: true });

const setFileProp = (trackingProjects, projectId, fileId, propObj) =>
	_.merge({}, trackingProjects, {
		projects: {
			// @ts-ignore
			[projectId]: {
				files: {
					[fileId]: propObj,
				},
			},
		},
	});

const markFileComplete = (trackingProjects, projectId, fileId, fileData) =>
	setFileProp(trackingProjects, projectId, fileId, { fileData, status: statusOptions.success });

const markFileFailure = (trackingProjects, projectId, fileId) =>
	setFileProp(trackingProjects, projectId, fileId, { status: statusOptions.failure });

const markFileCanceled = (trackingProjects, projectId, fileId) =>
	setFileProp(trackingProjects, projectId, fileId, { status: statusOptions.canceled });

const markFileActive = (trackingProjects, projectId, fileId, fileSize?) =>
	setFileProp(trackingProjects, projectId, fileId, { status: statusOptions.active, size: fileSize });

const markFilePercentageComplete = (trackingProjects, projectId, fileId, sizeComplete, percentageComplete) =>
	setFileProp(trackingProjects, projectId, fileId, { status: statusOptions.active, sizeComplete, percentageComplete });

const addStorageIssue = (
	trackingProjects,
	issueId,
	orgId,
	totalUploadSize,
	hasPlan
) =>
	_.merge(trackingProjects, {
		issue: { issueId, orgId, totalUploadSize, hasPlan },
	});

const clearStorageIssue = (trackingProjects) =>
	_.merge(trackingProjects, { issue: null });

const fileIsCompleted = (file) => file.status === statusOptions.success;
const fileHasFailed = (file) => file.status === statusOptions.failure;
const fileHasQueued = (file) => file.status === statusOptions.queued;

const projectIsPaused = (project) => project.status === statusOptions.paused;
const projectIsActive = (project) => project.status === statusOptions.active;
const projectIsCanceled = (project) => project.status === statusOptions.canceled;

const generateFileTrackingId = (f) => `${f.name}-${f.size}-${f.lastModified}`;
const projectHasFile = (trackingProjects, projectId, file) =>
	trackingProjects.projects?.[projectId]?.files?.[file.id];

const calculatePercentageComplete = (filesList): number => {
	let returnVal = null;
	if (filesList.length) {
		const totalPercentage = (filesList.reduce((acc, f) =>
			// If percentage available, use that or 100 for done and 0 for inprogress)
			acc += f["percentageComplete"] ?? (fileIsCompleted(f) ? 100 : 0)
		, 0) as number)
		const flooredVal = Math.floor(totalPercentage / filesList.length);
		returnVal = Math.min(flooredVal, 100);
	}
	return returnVal;
}

const deriveBatchesRenderState = (batches, filesList) =>
	batches?.map(batch => {
		const batchFiles = filesList.filter(x => x.batchId === batch.id);
		return Object.assign(
			{},
			batch,
			{
				percentageComplete: calculatePercentageComplete(batchFiles),
				totalFiles: batchFiles.length,
				completedFiles: batchFiles.filter(f => fileIsCompleted(f) || fileHasFailed(f)).length
			}
		)
	});

// NOTE: if this becomes a performance drag these calculations can be moved into the
// `addNewUploads`, `markFileFailure`, and `markFileComplete` functions to save on loops
export const deriveRenderState = (uploadState) => {
	const projectList = Object.values(uploadState.projects).filter(({ metaData, files }) => (!_.isEmpty(metaData) && !_.isNil(metaData.id) && !_.isEmpty(files)) ).map(
		({ metaData, status, files }) => {
			const filesList = Object.values(files),
				totalFiles = filesList.length,
				percentageComplete = calculatePercentageComplete(filesList),
				completedFiles = filesList.filter(f => fileIsCompleted(f) || fileHasFailed(f)).length,
				failedFileNames = filesList.filter(fileHasFailed).map( (f: File) => f.name ).join(', '),
				hasError = filesList.some(fileHasFailed);

			return {
				name: metaData.name,
				id: metaData.id,
				status,
				totalFiles,
				completedFiles,
				failedFileNames,
				hasError,
				percentageComplete,
				batches: deriveBatchesRenderState(metaData.batches, filesList),
				finished: status === statusOptions.canceled || completedFiles === totalFiles,
				files: filesList.map( (f: File) => ({
					// @ts-ignore
					...f,
					status: projectIsPaused({status}) || projectIsCanceled({status}) && fileHasQueued(f) ? status : f["status"] // Changed from . to [""] from Angular build error
				}) )
			};
		}
	);

	const projectStatuses = getProjectStatuses(projectList);

	const uploadIssue = projectList.filter(x => x.hasError)
		.map(proj => ({name: proj.name, failedFileNames: proj.failedFileNames}));

	return {
		status: projectStatuses,
		uploadIssue,
		projectList,
	};
};


const getProjectStatuses = (projectList) => {
	let activeStatus = projectList.reduce((acc, proj) => {
		if (!proj.finished) {
			projectIsActive(proj) ? acc.anyActive = true : acc.allActive = false;
			projectIsPaused(proj) ? acc.anyPaused = true : acc.allPaused = false;
		} else {
			acc.allActive = false;
			acc.allPaused = false;
		}
		return acc;
	}, {allActive: true, anyActive: false, allPaused: true, anyPaused: false});

	const allFinished = !(activeStatus.anyActive || activeStatus.anyPaused);
	return {allFinished, ...activeStatus}
}

export const getAcceptedImageTypes = (): string[] =>
	(
		flagLayer.isEnabled(availableFeatureFlags.iiqImageType) ?
			[...acceptedImageTypes, ".iiq"] :
			acceptedImageTypes
	);

export const getAcceptedImageTypesString = () =>
	getAcceptedImageTypes().join(", ");

export const filterImagesAcceptedTypes = (images: any, imageTypes: string[] = getAcceptedImageTypes() ): Array<any> =>
	[...images].filter(file =>
		imageTypes.some(type =>
			file?.name && type && file.name.toLocaleLowerCase().includes(type.toLocaleLowerCase())
		)
	);

const defaultTypes = [".las", ".laz"];
let importTypes = defaultTypes;

export type UploadItemsByType = { "Image": UploadItem[], "Map Layer": UploadItem[], "File": UploadItem[]  };
export type UploadType = "Image" | "Map Layer" | "File";
export enum uploadTypeEnum {
	image = 'Image',
	import = 'Map Layer',
	file = 'File',
}

export const importFileData = (): { [key: string]: FileData } => {
	return ({
		las: {name: 'LAS', extension: 'las', route: 'las'},
		laz: {name: 'LAZ', extension: 'laz', route: 'laz'},
		kml: {name: 'KML', extension: 'kml', route: 'overlays'},
		kmz: {name: 'KMZ', extension: 'kmz', route: 'overlays'},
		dxf: {name: 'DXF', extension: 'dxf', route: 'overlays'},
	})
}

export const getFileDataFromUpload = (file) => getImportFileData(getFileExtension(file.filename ?? file.name))[0];

export const getImportFileData = (fileExtension): Array<FileData> =>
	Object.values( importFileData() )
		.filter( ({extension}) =>
			extension.localeCompare( fileExtension, 'en', {sensitivity: 'base'}) === 0
		);

const makeFileTypesEnum = (inTypes = importTypes) => ({
	...getAcceptedImageTypes().reduce((acc, val) => {
		acc[val] = uploadTypeEnum.image;
		return acc;
	}, {}),
	...inTypes.reduce((acc, val) => {
		acc[val] = uploadTypeEnum.import;
		return acc;
	}, {}),
});

let fileTypesEnum = makeFileTypesEnum();

export enum uploadStateEnum {
	queued = "queued",
	uploading = "uploading",
	success = "success",
	failure = "failure"
}

export type UploadItem = {
	id: uuidv4;
	file: any,
	type: uploadTypeEnum;
	altType?: uploadTypeEnum;
	altQuery?: string;
	state: uploadStateEnum;
	uploadInfo: {
		project: Project,
		model?: Model,
		batch?: ImageBatch,
		location?: {
			longitude?: number,
			latitude?: number,
			altitude?: number
		}
	}; // batch_id, model_id, etc
}

const updateFileTypes = () => {
	const importsJSON = flagLayer.isEnabled("imports-list");
	return importsJSON ? JSON.parse(importsJSON) : defaultTypes;
}

export const convertFilesByType = (files): UploadItem[] => {

	if (isEqual(importTypes, defaultTypes)) {
		importTypes = updateFileTypes();
		fileTypesEnum = makeFileTypesEnum(importTypes);
	}

	return files?.reduce((acc, file) => {
		const extension = "." + extractFileExtension(file.name).toLowerCase();

		acc.push({
			id: uuidv4(),
			file,
			type: fileTypesEnum[extension] ?? uploadTypeEnum.file,
			state: uploadStateEnum.queued,
			uploadInfo: {}
		})
		return acc;
	}, [])
}


@Injectable()
export class UploadService {
	renderState = {
		status: {anyActive: false, anyPaused: false, allActive: false, allPaused: false, allFinished: false},
		uploadIssue: null,
		projectList: [],
	};

	get trackingProjects(): { projects: {}; issue: null } {
		return this._trackingProjects;
	}

	set trackingProjects(value: { projects: {}; issue: null }) {
		this.renderState = deriveRenderState(value);
		this._trackingProjects = value;
		this.debouncedUpdateProjectRenderState(value);
	}

	debouncedUpdateProjectRenderState = debounce(this.updateProjectRenderState, 250, { 'maxWait': 500 });

	public updateProjectRenderState(value) {
		this.renderState = deriveRenderState(value);
		this.projectListChange.next({
			renderState: this.renderState,
		});
		this._trackingProjects = value;
	};

	private _trackingProjects = { projects: {}, issue: null };

	public projectListChange = new BehaviorSubject({
		renderState: this.renderState,
	});

	public isLoggedIn: boolean = true;
	public isDeveloper: boolean = false;
	public batchId: number = null
	public batchPromise: Promise<number> = null;

	constructor(
		private _router: Router,
		private _modelService: ModelService,
		private _authService: AuthenticationService,
		private _imageService: ImageService,
		private _orgService: OrganizationService,
		private _projectService: ProjectService
	) {
		this._router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				let user = this._authService.user;
				this.isLoggedIn = user && user.id;
				this.batchPromise = null; // Make null for route change
				if (this.isLoggedIn && this.renderState) {
					this.projectListChange.next({
						// TODO: this may end up inundating the UI with updates, it can be moved into a debounced function to maintain a bit of sanity
						renderState: this.renderState,
					});
				}
			}
		});
	}

	isFileInactive(file) {
		return file.status === statusOptions.canceled || file.status === statusOptions.failure;
	}

	markProjectCanceled(projectId) {
		// remove queued uploads from tracking when a project is canceled
		let canceledProj = this.trackingProjects.projects[projectId];
		if(canceledProj) {
			for (let file in canceledProj.files) {
				if(canceledProj.files[file].status === statusOptions.queued) {
					delete canceledProj.files[file]
				}
			}
		}
		this.trackingProjects = markProjectCanceled(
			this.trackingProjects,
			projectId
		);
	}

	toggleProjectStatus(projectId) {
		this.trackingProjects = projectIsPaused(
			this.trackingProjects.projects[projectId]
		)
			? markProjectActive(this.trackingProjects, projectId)
			: markProjectPaused(this.trackingProjects, projectId);
	}

	async __uploadFiles({
		files,
		uploadItems,
		project_id,
		org_id,
		batch_id,
		batch_name,
		project,
	}: {
		files?: FileList | File[],
		uploadItems?: UploadItem[],
		project_id: number,
		org_id: number,
		batch_id?: number,
		batch_name?: string,
		project?: Project,
	}): Promise<any> {

		project_id = typeof project_id === "string" ? parseInt(project_id) : project_id;

		if (files?.length && !uploadItems?.length) {
			uploadItems = convertFilesByType(files);
		}

		const fileArray = [...uploadItems]
			.map((uploadItem) => {
				return Object.assign(uploadItem.file, {id: generateFileTrackingId(uploadItem.file), uploadType: uploadItem.type});
			}); // add id for tracking upload progress

		if (!this.trackingProjects?.projects?.[project_id] && project) {
			this.trackingProjects = addProjectMetaData( this.trackingProjects, project );

		}
		if (!_.isNil(batch_id))
			this.trackingProjects = this.addSkeletonBatch(this.trackingProjects, project_id, org_id, batch_id, batch_name, fileArray);

		this.updateProjectRenderState(this.trackingProjects);

		return this.checkOrgAndUpdate(org_id, project_id, batch_id, fileArray)
			.then(() => {

			return asyncjs.mapLimit(
				uploadItems,
				4,
				asyncjs.asyncify((
					uploadItem: UploadItem // `asyncify` is necessary to deal with Typescript targeting `< es2017` as noted here https://github.com/caolan/async/issues/1685#issuecomment-534508143
					) => {
						const file = (Object.assign(uploadItem.file, {id: generateFileTrackingId(uploadItem.file), uploadType: uploadItem.type}));
						return waitFor(() =>
							projectIsActive(this.trackingProjects.projects[project_id]) || projectIsCanceled(this.trackingProjects.projects[project_id])
						)
							.then(() => {
								if (projectIsCanceled(this.trackingProjects.projects[project_id])) {
									throw new Error('Aborted');
								}
							})
							.then(() => {
								this.trackingProjects = markFileActive(this.trackingProjects,
									project_id,
									file.id,
									file.size);
							})
							.then(() =>
								this.tryFileUpload(org_id, file, uploadItem)
							)
							.then((f) => {
								const resBody = f.body ? JSON.parse(f.body) : f;
								this.trackingProjects = markFileComplete(
									this.trackingProjects,
									project_id,
									file.id,
									resBody
								);
								return resBody;
							})
							.catch((e) => {
								// Null check is due to CORS errors returning responseCode 0, which is thrown as a null
								if (e === null || e?.message !== 'Aborted') {
									if (e === null) {
										console.warn('Null error code detected with image upload');
									}
									console.warn('Image failed upload');
									// only mark files which already exist in the project, otherwise previously removed files will get re-added
									if (projectHasFile(this.trackingProjects, project_id, file)) {
										this.trackingProjects = markFileFailure(
											this.trackingProjects,
											project_id,
											file.id
										);
									}
									console.error(e);
								} else {
									// only mark files which already exist in the project, otherwise previously removed files will get re-added
									if (projectHasFile(this.trackingProjects, project_id, file)) {
										this.trackingProjects = markFileCanceled(
											this.trackingProjects,
											project_id,
											file.id
										);
									}

									return e;
								}
							})
					}
				)
			);
		})
	}

	async checkOrgAndUpdate(org_id, project_id, batch_id, fileArray) {

		return this.checkOrg(org_id)
			.then(
				() =>
					project_id in this.trackingProjects.projects ?
						this.trackingProjects :
						(flagLayer.isEnabled(availableFeatureFlags.apiV2Routes)
							? this._projectService.getByIdV2(project_id)
							: this._projectService.getById(project_id)).then(( p ) => {
							return addProjectMetaData( this.trackingProjects, p );
						})
				)
			.then((trackingProjects) =>
				addNewUploads(trackingProjects, project_id, batch_id, fileArray)
			)
			.then((trackingProjects) => {
				this.trackingProjects = trackingProjects;
			})
	}

	checkOrg(org_id): Promise<any> {
		return this._orgService.getById(org_id).then(rtnOrg => {
			if (!rtnOrg?.subscription) throw new Error("No subscription on Organization", rtnOrg);
		});
	}

	tryFileUpload(org_id: number, file, uploadItem: UploadItem) {

		const { type, uploadInfo, altQuery } = uploadItem;
		const { project, batch, model } = uploadInfo;

		if (isNil(project?.id)) return Promise.reject("No project ID");
		if (type === uploadTypeEnum.image && isNil(batch?.id)) return Promise.reject("No batch ID.");

		const MATCH_KEY = true as any;
		const uploadMatcher = {
			[MATCH_KEY]: () => this.uploadDataFileWithPercentage(file, project.id),
			[(type === uploadTypeEnum.image && !!batch?.id) as any]: () => this.uploadImageWithPercentage(file, project.id, org_id, batch.id),
			[(type === uploadTypeEnum.import && !!model?.id) as any]: () => this.uploadModelImportV2WithPercentage(file, project.id, model.id, altQuery)
		}

		return retry(
			(bail) => (
					uploadMatcher[MATCH_KEY]()
				)
					.then((uploadedFile) => (batch?.id ? Object.assign(uploadedFile, batch.id) : uploadedFile))
					.catch((errorCode) => {
						if (bailCodes.includes(errorCode)) {
							bail(new Error(errorCode));
						} else {
							console.warn(`${type} upload failed, retrying...`);
							throw new Error(errorCode); // we need to wrap the errorCode in case we get something unexpected like `null` which `async-retry` can't handle
						}
					}),
			{
				retries: 5,
				minTimeout: 100
			}
		);
	}

	clearIssue() {
		this.trackingProjects = clearStorageIssue(this.trackingProjects);
	}

	isImageFile = ({ type, content_type, name } = { type: "", content_type: "", name: ""}) => {
		let extension = getFileExtension(name).toLowerCase();
		return /image/.test(type || content_type) || extension === "iiq";
	}

	async uploadImage(file, projectId, orgId, batchId): Promise<any> {
		return flagLayer.isEnabled(availableFeatureFlags.apiV2Images)
			? this._imageService.uploadImagesToBatchV2(batchId, file)
			: this._imageService.uploadImagesToBatch(batchId, file);
	}

	async uploadImageWithPercentage(file, projectId, orgId, batchId): Promise<any> {
		const image = await file['arrayBuffer']();

		return this._imageService.uploadImagesToBatchV2WithPercentage(batchId, file, image).pipe(
			tap(resp => {
				if (resp?.type === 1 && resp.loaded && resp.total) {
					const percentDone = Math.round(100 * resp.loaded / resp.total);

					this.trackingProjects = markFilePercentageComplete(
						this.trackingProjects,
						projectId,
						file["id"],
						resp.loaded,
						percentDone
					);
				}
			}),
			last()
		).toPromise();
	}

	uploadDataFileWithPercentage(file, projectId): Promise<any> {
		return this._projectService.addFileWithPercentageV2(projectId, file, file.name).pipe(
			tap(resp => {
				if (resp?.type === 1 && resp.loaded && resp.total) {
					const percentDone = Math.round(100 * resp.loaded / resp.total);

					this.trackingProjects = markFilePercentageComplete(
						this.trackingProjects,
						projectId,
						file["id"],
						resp.loaded,
						percentDone
					);
				}
			}),
			last()
		).toPromise();
	}

	addSkeletonBatch(trackingProjects, project_id, organization_id, batch_id, batch_name, files): any {
		if (_.isEmpty(trackingProjects.projects[project_id]))
			trackingProjects.projects[project_id] = { metaData: { id: project_id } };

		const project = trackingProjects.projects[project_id];

		if (project.metaData?.batches?.length) {
			const existing = project.metaData?.batches.find(byId(batch_id));
			if (isNil(existing?.id)) {
				const skeleton = {
					id: batch_id,
					project_id,
					images: files,
					name: batch_name,
					organization_id,
					active: 1,
				};
				project.metaData.batches = [...(project.metaData.batches ?? []), (skeleton)];
			}
		}
		return trackingProjects;
	}

	uploadModelImports(importFiles: ImportFile[], model_id: number, project: Project): void {

		const fileArray = [...importFiles] // Ensure files uploaded are converted from FileList to the correct format (Array<File>)
			.map((f) => Object.assign( f, { id: generateFileTrackingId( f.file ) } )); // add id for tracking upload progress

		this.addExteriorProjectUpload(project, fileArray);

		fileArray.forEach(file => {
			this.uploadModelImport(file, model_id, project);
		});
	}

	uploadModelImport(importFile: ImportFile, model_id: number, project: Project): void {

		const fileRoute = importFile.selectedFileData.route;

		this._modelService.uploadModel(importFile.file, model_id, fileRoute).subscribe((resp) => {
			this.handleUploadWithPercentage(project, importFile, resp);
		})
	}

	async uploadModelImportV2(file: File, model_id: number): Promise<any> {

		const fileData = getFileDataFromUpload( file );
		const fileRoute = fileData.route;
		return this._modelService.uploadModelV2(file, model_id, fileRoute);
	}

	async uploadModelImportV2WithPercentage(file: File, project_id: number, model_id: number, altQuery?: string): Promise<any> {
		const fileData = getFileDataFromUpload( file );
		const fileRoute = fileData.route;

		return this._modelService.uploadModelWithPercentageV2(file, model_id, fileRoute, altQuery).pipe(
			tap(resp => {
				if (resp?.type === 1 && resp.loaded && resp.total) {
					const percentDone = Math.round(100 * resp.loaded / resp.total);

					this.trackingProjects = markFilePercentageComplete(
						this.trackingProjects,
						project_id,
						file["id"],
						resp.loaded,
						percentDone
					);
				}
			}),
			last()
		).toPromise();
	}

	handleUploadWithPercentage(project, importFile, resp) {
		if (resp.type === HttpEventType.UploadProgress) {
			const percentDone = Math.round(100 * resp.loaded / resp.total);
			this.updateExteriorProjectProgress(project, importFile, resp.loaded, percentDone)
		} else if (resp.type === HttpEventType.Response) {
			this.completeExteriorProjectUpload(project, importFile)
		}
	}

	addExteriorProjectUpload(project: Project, fileArray: ImportFile[]): void {
		let trackingProjects = addProjectMetaData(this.trackingProjects, project);
		trackingProjects = addNewUploads(trackingProjects, project.id, null, fileArray);
		this.trackingProjects = trackingProjects;
	}

	updateExteriorProjectProgress(project: Project, importFile: ImportFile, sizeProgress, progress): void {
		if (project.id && importFile?.["id"]) {
			this.trackingProjects = markFilePercentageComplete(
				this.trackingProjects,
				project.id,
				importFile["id"],
				sizeProgress,
				progress
			);
		}
	}

	completeExteriorProjectUpload(project: Project, importFile): void {
		if (project.id && importFile?.["id"]) {
			this.trackingProjects = markFileComplete(
				this.trackingProjects,
				project.id,
				importFile.id,
				importFile
			);
		}
	}
}
