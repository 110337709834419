
<div class="share-container" [ngClass]="{'confirmation-container' : showSuccessMessage}">
	<ng-container *ngIf="!showSuccessMessage">
		<button
				data-testid="close-button"
				class="floating-close-button"
				matTooltip="Close"
				mat-icon-button
				(click)="closeShare()">
			<mat-icon>close</mat-icon>
		</button>
		<ng-container *ngIf="!showInvited">
			<ng-container *ngTemplateOutlet="t_orgInvite"></ng-container>
		</ng-container>
		<ng-container *ngIf="showInvited">
			<ng-container *ngTemplateOutlet="t_sharedWith"></ng-container>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="showSuccessMessage">
		<mat-icon class="confirm-check">check</mat-icon>
		<h2 class="H2Black700">
			{{ setPlural('Invitation', invitedList.length) }} sent!
		</h2>
		<p class="new-members">
			The new {{ setPlural('member', invitedList.length) }} will receive an email invitation from you.
		</p>
		<button mat-stroked-button class="P1Blue500" matDialogClose>Dismiss</button>
	</ng-container>
</div>


<ng-template #t_orgInvite>
	<div class="title">Share with others</div>
	<div class="uppercase" style="padding-top: 40px;">Invite members</div>

	<mat-tab-group
		animationDuration="0ms"
		disableRipple=true
		(selectedIndexChange)="selectedTabIndex = $event">

		<mat-tab label="Invite by email">
			<ng-container *ngTemplateOutlet="t_emailInvite"></ng-container>
		</mat-tab>

		<mat-tab label="Invite by link">
			<ng-container *ngTemplateOutlet="t_linkInvite"></ng-container>
		</mat-tab>
	</mat-tab-group>

	<ng-container *ngIf="selectedTabIndex === 0">
		<div class="shared-with-container">
			<span data-testid="shared-with-text" class="shared-with-text" *ngIf="!displayError && !emailControl.value && !(invitedList?.length)" (click)="showInvited = true;">
				Shared with {{getOrgViewList(orgDisplayList)}}
				<ng-container *ngIf="shareUserList.length > 3">and {{shareUserList.length - orgDisplayList.length}}+</ng-container>
			</span>
			<div class="shared-with-tip" *ngIf="!displayError && emailControl.value || (invitedList?.length)">Hit ‘enter’ once you have entered an email address.</div>
		</div>
		<div class="invite-buttons">
			<button mat-stroked-button color="primary" matDialogClose class="cancel-button">Cancel</button>
			<button mat-stroked-button color="primary" data-testid="send-invite-button" [disabled]="!enableInvite()" (click)="sendInvites();">{{invitedList.length > 1 ? 'Send invites' : 'Send invite'}}</button>
		</div>
	</ng-container>

	<div class="alert-text">
		<div class="P1Red" *ngIf="displayError">{{displayError}}</div>
		<div class="P1Black500" *ngIf="displayMessage">{{displayMessage}}</div>
	</div>

</ng-template>

<ng-template #t_emailInvite>
	<div class="invite-by-email">
		<div class="custom-invite">
			<button mat-button class="invited-button" matTooltip="Click to edit" *ngFor="let invited of invitedList" (click)="editInvite(invited);">
				<span>{{invited.email}}</span>
				<mat-icon class="invited-icon" (click)="removeInviteFromList(invited);" matTooltip="Remove" fontSet="material-icons-outlined">cancel</mat-icon>
			</button>
			<input type="email"
				class="H6Black700Regular invitation-input"
				placeholder="{{invitedList.length > 0 ? 'Enter another email' : '+ Invite members by entering their email address'}}"
				(ngModelChange)="displayError = null;"
				(focus)="hideText(true);"
				(focusout)="hideText(false)"
				pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i"
				autocomplete="off"
				[matAutocomplete]="auto"
				(keydown.enter)="addInviteToList()"
				[formControl]="emailControl">
			<mat-autocomplete #auto="matAutocomplete" class="autocomplete" (optionSelected)="addInviteToList()">
				<mat-option
					class="option"
					*ngFor="let opt of filteredEmailSuggestions | async"
					[value]="opt">
					<div class="epsg-view">{{ opt }}</div>
				</mat-option>
			</mat-autocomplete>
		</div>

		<ng-container *ngTemplateOutlet="t_invitePermissions"></ng-container>
	</div>
</ng-template>

<ng-template #t_linkInvite>
	<div>
		<div class="invite-text">Any Mapware user with this link can open with the selected permission:</div>
		<div class="invite-by-link">
			<ng-container *ngTemplateOutlet="t_invitePermissions"></ng-container>
			<app-share-link
				[createLinkFunc]="createLink"
				[shareLink]="shareLink"
				(shareLinkChange)="updateShareLink($event)">
			</app-share-link>
		</div>
	</div>
</ng-template>

<ng-template #t_sharedWith>
	<div class="title" style="display: flex;">
		<button *ngIf="!onlySharedWithYou"
				mat-icon-button
				matTooltip="Back"
				(click)="showInvited = false;"
				class="share-back-button">
			<mat-icon>keyboard_backspace</mat-icon>
		</button>
		Shared with {{onlySharedWithYou ? 'you' : ''}}
	</div>
	<div *ngIf="onlySharedWithYou" class="P1Black500">Contact the project owner to share with someone else</div>
	<div class="total-members">{{shareUserList.length}} Members total</div>

	<mat-divider></mat-divider>

	<div class="share-user-list" *ngFor="let orgUser of shareUserList">
		<aerial-avatar>{{(orgUser.first_name ? orgUser.first_name[0] : '') + '' + (orgUser.last_name ? orgUser.last_name[0] : '')}}</aerial-avatar>
		<span>
			<div class="P1Black700">{{orgUser.first_name + ' ' + orgUser.last_name}}</div>
			<div class="P1Black500">{{orgUser.email}}</div>
		</span>
		<span class="team-rank" *ngIf="!orgUser.shareId">Team {{getRole(orgUser.role).text}}</span>
		<span class="team-rank" *ngIf="orgUser.shareId">
			Shared: {{getRole(orgUser.role).text}}
			<span class="link P1Blue500" (click)="removeShare(orgUser);" matTooltip="Stop sharing with this user">Remove</span>
		</span>
	</div>
</ng-template>

<ng-template #t_invitePermissions>
	<mat-form-field subscriptSizing="dynamic" appearance="fill" class="invite-options">
		<mat-select [(value)]="userPermissions">
			<mat-option *ngFor="let role of permissionList" [value]="role.value">
				{{role.text}}
			</mat-option>
		</mat-select>
	</mat-form-field>
</ng-template>
