
import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { GlobalService } from '@shared/services';
import { isEmpty, isNil } from 'lodash';
import { MatMenuTrigger } from '@angular/material/menu';
import {uploadedStates} from '@app/components/image-groups';

@Component({
	selector: 'app-image',
	templateUrl: './image.component.html',
	styleUrls: ['./image.component.scss']
})
export class ImageComponent {

	@Input() sensor;
	@Input() uploaded: boolean = false;
	@Input() listType: any = 'small';
	@Input() disableMove: boolean = false;
	@Input() index: number = 0;

	@Input('data') set inData(data) {
		if (!isNil(data?.project_id) && !isEmpty(data?.image)) {
			const { image, project_id } = data;
			this.image = image;
			this.project_id = project_id;
		}
	}

	@Input() showInfo: boolean = false;
	@Input() hasPermissions;
	@Input() selected: boolean = false;
	@Output() selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output('outEvent') outEventEmitter: EventEmitter<any> = new EventEmitter();

	@ViewChild('itemMenuTrigger', {read: MatMenuTrigger}) menuTrigger: MatMenuTrigger;

	public image;
	public project_id;
	public file_url = GlobalService.databaseApiUrl;

	constructor(
		
	) {

	}

	waitAndReload(event) {

		const originalSrc = event.target.src;

		if (parseInt(event.target.getAttribute('data-retry'), 10) !== parseInt(event.target.getAttribute('data-max-retry'), 10)) {

			event.target.setAttribute('data-retry', parseInt(event.target.getAttribute('data-retry'), 10) + 1);

			event.target.src = '/assets/images/placeholder.png';

			setTimeout(function () {
				event.target.src = originalSrc;
			}, 2000);
		} else {
			event.target.src = '/assets/images/placeholder.png';
		}
	}

	toggleInfo(e) {
		this.showInfo = !this.showInfo;
		e.stopPropagation();
	}

	openImage(image = this.image) {
		if (image?.id && image.batch_id) {
			const url = `/photos/${image.batch_id}/${image.guid}`;
			window.open('/#' + url, '_blank');
		}
	}

	openMenu(e): void {
		this.menuTrigger.openMenu();
		e.preventDefault();
		e.stopPropagation();
	}

	outEvent(type): void {
		if (type === "open") this.openImage();
		else this.outEventEmitter.emit({type, images: [this.image]});
	}

	isComplete(image) {
		return isNil(image.status) || uploadedStates[image.status.toLowerCase()];
	}

	isFailure(image) {
		return !uploadedStates[image.status.toLowerCase()];
	}

	onSelectionChange(event) {
		this.selectedChange.emit(event);
	}

}
