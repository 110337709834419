import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Project } from "@shared/models/project.model";
import { MapRendererComponent } from "../../pages/map/renderer/map.renderer.component";

@Component({
	selector: "app-viewer-import-markers-modal",
	templateUrl: "./marker-import.dialog.html",
	styleUrls: ["./marker-import.dialog.scss"],
})
export class MarkerImportDialog implements OnInit {
	parentProject: Project;
	mapRenderer: MapRendererComponent;

	markerCount: number = 0;
	markerFields: string[] = [];
	csvParseMessage: string = "No CSV to parse.";

	markers: any = [];

	constructor(
		public _dialogRef: MatDialogRef<MarkerImportDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.parentProject = data.project;
		this.mapRenderer = data.renderer;
	}

	ngOnInit() {
	}

	updateCSV(event) {
		this.markerCount = 0;
		this.markerFields = [];
		this.csvParseMessage = "Parsing CSV...";

		let csvString = event.target.value;
		if(csvString && csvString.length > 1) {
			try {
				let data = this.parseCSV(csvString);
			
				if(data.length > 1)	{
					let headerFieldCount = data[0].length;

					for(let row of data) {
						if(row.length != headerFieldCount) {
							this.csvParseMessage = "Row Fields don't match header";
							return;
						}
					}

					// CSV must be ok
					this.markerCount = data.length - 1;
					this.markerFields = data[0];
					this.csvParseMessage = "CSV looks good";

					// format the markers
					this.markers = [];
					for(let i = 1; i < data.length; i++) {
						let marker = {};
						marker["name"] = data[i][0];
						marker["fields"] = {};
						for(let j = 1; j < data[i].length; j++) {
							marker["fields"][data[0][j]] = data[i][j];
						}
						this.markers.push(marker)
					}
				}
				else {
					this.csvParseMessage = "CSV doesn't have enough rows";
				}
			} catch(e) {
				console.error("Error Parsing CSV: ", e);
				this.csvParseMessage = "CSV parsing error";
			}
		}
	}

	importMarkers() {
		this.mapRenderer.importCustomMarkers(this.markers);
		this.close();
	}

	close(rtn: any = null): void {
		this._dialogRef.close(rtn);
	}

	parseCSV(csvString, options = {}) {

		const config = {
			delimiter: options['delimiter'] ? options['delimiter'] : "",
			newline: options['newline'] ? options['newline'] : "",
			quoteChar: '"',
			escapeChar: options['escapeChar'] ? options['escapeChar'] : '"',
			header: false,
			transformHeader: undefined,
			dynamicTyping: false,
			preview: 0,
			encoding: "",
			worker: false,
			comments: options['comments'] ? options['comments'] : '',
			step: undefined,
			complete: undefined,
			error: undefined,
			download: false,
			skipEmptyLines: false,
			chunk: undefined,
			fastMode: undefined,
			beforeFirstChunk: undefined,
			withCredentials: undefined,
			transform: undefined
		};
		//@ts-ignore
		return Papa.parse(csvString, config).data;
	}
}
