
<div class="table">
	<div class="table-header">
		@if (organizationList?.length) {
			<mat-form-field [ngClass]="{'disabled': isLoading}" subscriptSizing="dynamic" appearance="fill">
				<mat-label>Select Organization</mat-label>
				<mat-select [(value)]="selectedOrganization" (selectionChange)="selectOrg($event?.value)">
					@for (org of organizationList; track org.id){
						<mat-option [value]="org" class="product-option">
							<div class="product-item">
								{{org.name}}
								<span class="P2Black500">
									ID: {{ org.id }}
								</span>
							</div>
							<div class="P2Black500">
								{{ hasActiveOrgSub(org) ? "Active" : "Inactive"}}
								|
								{{ org.processing_credits + org.subscription_credits | comma }} credits
							</div>
						</mat-option>
					}
					<mat-select-trigger *ngIf="selectedOrganization" class="H7Black700Semibold">
						{{selectedOrganization.name}}
						<div class="P2Black500">
							{{ hasActiveOrgSub(selectedOrganization) ? "Active" : "Inactive"}}
							|
							{{ selectedOrganization.processing_credits + selectedOrganization.subscription_credits | comma }} credits
						</div>
					</mat-select-trigger>
				</mat-select>
			</mat-form-field>
		}
	</div>
	@if (!isLoading && selectedOrganization?.id) {
		<div class="period-container" [ngClass]="{'disabled': !userIsAdmin}">
			<ng-container *ngFor="let option of periodOptions">
				<div class="period" [ngClass]="{'active-period': isSelectedPeriod(option)}"
					 (click)="changePeriod(option)">
					{{option.name}}
				</div>
			</ng-container>
		</div>
		<ng-container *ngTemplateOutlet="t_subscriptionSelection"></ng-container>
	}
	@if (isLoading) {
		<div class="loading-container">
			<div class="dot-stretching"></div>
		</div>
	}
</div>


<ng-template #t_subscriptionSelection>
	<div class="products-container elevation-z2" *ngIf="filteredProducts?.length" [ngClass]="{'disabled': !userIsAdmin}">
		<mat-radio-group [(ngModel)]="selectedProduct">
			<ng-container *ngFor="let product of filteredProducts">
				<div class="product"
					 [ngClass]="{'selected-product elevation-z5': isSelectedProduct(product), 'active-product': isOrgProduct(product)}">
					<div class="product-header" (click)="setSelectedProduct(product)">
						<mat-radio-button [value]="product"></mat-radio-button>

						<div class="product-block">
							<div class="product-title">
								<div class="H5Black700 bold ellipsis">{{ product.name }}</div>
								<div *ngIf="isOrgProduct(product, selectedOrganization)"
									 class="current-product"
									 [matTooltip]="getProductTooltip(selectedOrganization.subscription?.cancel_at) | date"
									 [matTooltipDisabled]="!selectedOrganization.subscription?.cancel_at"
									 matTooltipPosition="right"
									 [ngClass]="getProductStatusClass(selectedOrganization)">
									{{ getProductStatusText(selectedOrganization) }}
								</div>
							</div>
							<p class="P2Black500">{{product.description ?? 'No description.'}}</p>
						</div>

						<div class="price-block" *ngIf="product.price?.recurring">
							<div class="H5Black500">{{ ( product.price.unit_amount / 100 ) | currency }}</div>
							<div class="P2Black500">{{ periodMap[product.price.recurring.interval] ?? ""}}
							</div>
						</div>
					</div>

					@if ((isSelectedProduct(product)) ) {
						<div class="product-footer">
							<div class="product-info">
								<ng-container *ngIf="product.metadata?.plan_features?.length">
									<div *ngFor="let feature of product.metadata.plan_features" class="feature-block">
										<mat-icon class="">check</mat-icon>
										{{ feature }}
									</div>
								</ng-container>
							</div>
							<button mat-flat-button
									color="primary"
									(click)="submit(product);">
								@if (isSubmitting) {
									<span class="loading-container">
										<div class="dot-stretching"></div>
									</span>
								} @else {
									<span class="select-text">
										{{getPlanSelectText( product, selectedOrganization )}}
										<mat-icon *ngIf="!isChangePlan(product)">open_in_new</mat-icon>
									</span>
								}
							</button>
						</div>
					}
				</div>
			</ng-container>
		</mat-radio-group>
	</div>
</ng-template>
